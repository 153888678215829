/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const CountDownTimer = ({ date }) => {
  const [timeUp, setTimeUp] = useState(false);
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const countdown = setInterval(() => {
      const lastDay = +new Date(date);
      const currentDay = +new Date(dayjs().tz('Europe/Paris', true).format());
      const difference = lastDay - +currentDay;
      if (difference < 1) {
        setTimeUp(true);
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        setTimeLeft({
          days,
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  return (
    <>
      {timeLeft && (
        <ul className="flex flex-wrap justify-between rounded-lg border-4 border-primary bg-slate-300/20 p-5 text-primary">
          <li>
            <p>
              <span className="mb-1 block text-2xl text-secondary">
                {timeLeft?.days > 9
                  ? timeLeft?.days?.toString()
                  : `0${timeLeft?.days?.toString()}`}
              </span>
              <span>jours</span>
            </p>
          </li>
          <li className="text-2xl text-primary">:</li>
          <li>
            <p>
              <span className="mb-1 block text-2xl text-secondary">
                {timeLeft?.hours > 9
                  ? timeLeft?.hours?.toString()
                  : `0${timeLeft?.hours?.toString()}`}
              </span>
              <span>heures</span>
            </p>
          </li>
          <li className="text-2xl text-primary">:</li>
          <li>
            <p>
              <span className="mb-1 block text-2xl text-secondary">
                {timeLeft?.minutes > 9
                  ? timeLeft?.minutes?.toString()
                  : `0${timeLeft?.minutes?.toString()}`}
              </span>
              <span>minutes</span>
            </p>
          </li>
          <li className="text-2xl text-primary">:</li>
          <li>
            <p>
              <span className="mb-1 block text-2xl text-secondary">
                {timeLeft?.seconds > 9
                  ? timeLeft?.seconds?.toString()
                  : `0${timeLeft?.seconds?.toString()}`}
              </span>
              <span>secondes</span>
            </p>
          </li>
        </ul>
      )}
      <div />
    </>
  );
};

CountDownTimer.propTypes = {
  date: PropTypes.string.isRequired,
};

export default CountDownTimer;
