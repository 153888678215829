/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { getAnalyticStats, getStats } from 'api/user';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import Header from 'components/Header';
import SubHeader from 'components/SubHeader';
import { fr } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useOutsideClick from 'utils/useOutsideClick';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const Dashboard = () => {
  const navigate = useNavigate();
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };

  const labels = [
    '24 mars',
    '25 mars',
    '26 mars',
    '27 mars',
    '28 mars',
    '29 mars',
    '30 mars',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: [0, 0, 0, 0, 0, 0, 0],
        borderColor: '#E6B139',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  const [period, setPeriod] = useState('1w');
  const [stats, setStats] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);

  const closeStartCalendar = () => {
    setShowStartCalendar(false);
  };

  const closeEndCalendar = () => {
    setShowEndCalendar(false);
  };

  const calendarStartDateRef = useOutsideClick(closeStartCalendar);
  const calendarEndDateRef = useOutsideClick(closeEndCalendar);

  const redirect = () => {
    const location = window.location.href;
    setTimeout(() => {
      navigate(`/se-connecter?redirect=${location}`);
    }, 500);
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    redirect();
  };

  const fetchStats = () => {
    getStats()
      .then((res) => {
        setStats(
          res.data?.map((stat) => {
            return {
              donations: stat.total_amount_dons,
              dailyDonations: stat.total_amount_dons_of_day,
              supporters: stat.total_supporter,
            };
          })
        );
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          logOut();
        }
      });
  };

  const fetchAnalyticStats = (e) => {
    e?.preventDefault();

    const formData = new FormData();
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);

    getAnalyticStats(formData)
      .then(() => {})
      .catch((err) => {
        if (err?.response?.status === 403) {
          logOut();
        }
      });
  };

  useEffect(() => {
    fetchStats();
    fetchAnalyticStats();
  }, []);

  const setRange = (range) => {
    switch (range) {
      case '1w':
        // start date = today - 7 days
        setStartDate(new Date(new Date().setDate(new Date().getDate() - 7)));
        setEndDate(new Date());
        fetchAnalyticStats();
        break;
      case '2w':
        // start date = today - 14 days
        setStartDate(new Date(new Date().setDate(new Date().getDate() - 14)));
        setEndDate(new Date());
        fetchAnalyticStats();
        break;
      case '1m':
        // start date = today - 30 days
        setStartDate(new Date(new Date().setDate(new Date().getDate() - 30)));
        setEndDate(new Date());
        fetchAnalyticStats();
        break;
      case '3m':
        // start date = today - 90 days
        setStartDate(new Date(new Date().setDate(new Date().getDate() - 90)));
        setEndDate(new Date());
        fetchAnalyticStats();
        break;
      case '6m':
        // start date = today - 180 days
        setStartDate(new Date(new Date().setDate(new Date().getDate() - 180)));
        setEndDate(new Date());
        fetchAnalyticStats();
        break;
      case '1y':
        // start date = today - 365 days
        setStartDate(new Date(new Date().setDate(new Date().getDate() - 365)));
        setEndDate(new Date());
        fetchAnalyticStats();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setRange(period);
  }, [period]);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Mon compte - KOUDPOUSS" />

      <Header />
      <section className="bg-white px-6 pt-6 pb-10">
        {/* Menus */}
        <SubHeader currentPage={0} />
        {/* Resume */}
        <nav className="flex flex-wrap items-center pr-10 sm:px-6">
          <span className="mr-12">Résumé</span>
          <ul className="flex space-x-1 sm:space-x-8">
            <li
              role="button"
              className={`inline-flex cursor-pointer items-center gap-2 rounded-lg bg-transparent py-3 px-4 text-center font-medium text-gray-500 hover:text-primary hs-tab-active:bg-primary hs-tab-active:text-white
              ${period === '1w' ? 'active' : ''}`}
              onClick={() => setPeriod('1w')}
            >
              1S
            </li>
            <li
              role="button"
              className={`inline-flex cursor-pointer items-center gap-2 rounded-lg bg-transparent py-3 px-4 text-center font-medium text-gray-500 hover:text-primary hs-tab-active:bg-primary hs-tab-active:text-white
              ${period === '2w' ? 'active' : ''}`}
              onClick={() => setPeriod('2w')}
            >
              2S
            </li>
            <li
              role="button"
              className={`inline-flex cursor-pointer items-center gap-2 rounded-lg bg-transparent py-3 px-4 text-center font-medium text-gray-500 hover:text-primary hs-tab-active:bg-primary hs-tab-active:text-white
              ${period === '1m' ? 'active' : ''}`}
              onClick={() => setPeriod('1m')}
            >
              1M
            </li>
            <li
              role="button"
              className={`inline-flex cursor-pointer items-center gap-2 rounded-lg bg-transparent py-3 px-4 text-center font-medium text-gray-500 hover:text-primary hs-tab-active:bg-primary hs-tab-active:text-white
              ${period === '3m' ? 'active' : ''}`}
              onClick={() => setPeriod('3m')}
            >
              3M
            </li>
            <li
              role="button"
              className={`inline-flex cursor-pointer items-center gap-2 rounded-lg bg-transparent py-3 px-4 text-center font-medium text-gray-500 hover:text-primary hs-tab-active:bg-primary hs-tab-active:text-white
              ${period === '6m' ? 'active' : ''}`}
              onClick={() => setPeriod('6m')}
            >
              6M
            </li>
            <li
              role="button"
              className={`inline-flex cursor-pointer items-center gap-2 rounded-lg bg-transparent py-3 px-4 text-center font-medium text-gray-500 hover:text-primary hs-tab-active:bg-primary hs-tab-active:text-white
              ${period === '1a' ? 'active' : ''}`}
              onClick={() => setPeriod('1a')}
            >
              1A
            </li>
          </ul>
        </nav>
        {/* Dates */}
        <form
          className="mt-6 flex flex-wrap sm:pl-6 md:justify-end md:gap-2"
          onSubmit={fetchAnalyticStats}
        >
          <span className="mt-4">Du</span>
          <div
            ref={calendarStartDateRef}
            className="relative mb-6 flex w-1/2 flex-col items-start overflow-x-auto sm:w-auto sm:overflow-x-visible"
          >
            <button
              onClick={() => setShowStartCalendar(true)}
              type="submit"
              className="w-full rounded-full border-2 border-slate-300 py-3 sm:mr-5 sm:pl-4 sm:pr-28"
            >
              {startDate.toLocaleDateString('fr')}
            </button>
            {showStartCalendar && (
              <Calendar
                className="rounded shadow-md shadow-slate-200 sm:absolute sm:left-0 sm:top-full sm:z-10"
                date={startDate}
                maxDate={new Date(new Date().getFullYear(), 11, 31)}
                onShownDateChange={(date) => {
                  const currentDate = new Date(date);
                  const currentStartDate = new Date(date);
                  // set birthDate to current date
                  currentDate.setDate(startDate.getDate() + 1);
                  currentStartDate.setDate(startDate.getDate());
                  setStartDate(currentDate);
                  setStartDate(currentStartDate);
                }}
                onChange={(date) => {
                  const currentDate = new Date(date);
                  // add 1 day to current date
                  currentDate.setDate(currentDate.getDate() + 1);
                  setStartDate(currentDate);
                  setStartDate(date);
                  setShowStartCalendar(false);
                }}
                locale={fr}
                color="#FD6E56B7"
              />
            )}
          </div>
          <span className="mt-4">Au</span>
          <div
            ref={calendarEndDateRef}
            className="relative mb-6 flex w-1/2 flex-col items-start overflow-x-auto sm:w-auto sm:overflow-x-visible"
          >
            <button
              onClick={() => setShowEndCalendar(true)}
              type="submit"
              className="w-full rounded-full border-2 border-slate-300 py-3 sm:mr-5 sm:pl-4 sm:pr-28"
            >
              {endDate.toLocaleDateString('fr')}
            </button>
            {showEndCalendar && (
              <Calendar
                className="rounded shadow-md shadow-slate-200 sm:absolute sm:left-0 sm:top-full sm:z-10"
                date={endDate}
                maxDate={new Date(new Date().getFullYear(), 11, 31)}
                onShownDateChange={(date) => {
                  const currentDate = new Date(date);
                  const currentEndDate = new Date(date);
                  // set birthDate to current date
                  currentDate.setDate(endDate.getDate() + 1);
                  currentEndDate.setDate(endDate.getDate());
                  setEndDate('endDate', currentDate);
                  setEndDate(currentEndDate);
                }}
                onChange={(date) => {
                  const currentDate = new Date(date);
                  // add 1 day to current date
                  currentDate.setDate(currentDate.getDate() + 1);
                  setEndDate('endDate', currentDate);
                  setEndDate(date);
                  setShowEndCalendar(false);
                }}
                locale={fr}
                color="#FD6E56B7"
              />
            )}
          </div>
          <div className="mb-6">
            <button
              type="submit"
              className="button-glassmorphism w-36 rounded-full py-3 text-white"
            >
              Filtrer
            </button>
          </div>
        </form>
        {/* Amounts */}
        <ul className="flex flex-col flex-wrap items-center text-white sm:flex-row sm:px-6">
          <li className="max-w-xs">
            <div className="relative mt-10 select-none  before:absolute before:left-10 before:-bottom-[10px] before:h-0 before:w-0 before:border-x-[10px] before:border-t-[10px] before:border-x-transparent before:border-t-secondary">
              <article className="relative mx-auto w-64 rounded-md bg-secondary pt-6 pb-8 pl-4">
                <h2 className="text-2xl">{stats?.donations || 0} €</h2>
                <p>Dons totaux collectés</p>
              </article>
            </div>
            <figure className="relative mx-auto mt-4 w-10/12">
              <img
                src="/assets/wisely_title.webp"
                alt="Dons totaux collectés"
              />
            </figure>
          </li>
          <li className="max-w-xs">
            <div className="relative mt-10 select-none  before:absolute before:left-10 before:-bottom-[10px] before:h-0 before:w-0 before:border-x-[10px] before:border-t-[10px] before:border-x-transparent before:border-t-secondary">
              <article className="relative mx-auto w-64 rounded-md bg-secondary pt-6 pb-8 pl-4">
                <h2 className="text-2xl">{stats?.dailyDonations || 0} €</h2>
                <p>Dons collectés aujourd&apos;hui</p>
              </article>
            </div>
            <figure className="relative mx-auto mt-4 w-10/12">
              <img
                src="/assets/wisely_title.webp"
                alt="Dons totaux collectés"
              />
            </figure>
          </li>
          <li className="max-w-xs">
            <div className="relative mt-10 select-none before:absolute before:left-10 before:-bottom-[10px] before:h-0 before:w-0 before:border-x-[10px] before:border-t-[10px] before:border-x-transparent before:border-t-yellow-500 sm:ml-6">
              <article className="button-glassmorphism relative mx-auto w-64 rounded-md pt-6 pb-8 pl-4">
                <h2 className="text-2xl">{stats?.supporters || 0}</h2>
                <p className="text-white">Supporteurs</p>
              </article>
            </div>
            <figure className="relative mx-auto mt-4 w-10/12">
              <img
                src="/assets/wisely_title.webp"
                alt="Dons totaux collectés"
              />
            </figure>
          </li>
        </ul>
        {/* Statistics */}
        {/* <section className="h-52">
          <Line options={options} data={data} />
        </section> */}
      </section>
    </div>
  );
};

export default Dashboard;
