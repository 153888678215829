import { publicRequest, userRequest } from '.';

export const login = async (user) => {
  return publicRequest.post('auth/login', user);
};

export const confirmAccount = async (token) => {
  return publicRequest.get(`auth/confirm/${token}`);
};

export const register = async (user) => {
  return publicRequest.post('auth/register', user);
};

export const request = async (email) => {
  return publicRequest.post('auth/resetpassword', email);
};

export const reset = async (data) => {
  return publicRequest.put('auth/updatepassword', data);
};

export const resetPassword = async (data) => {
  return publicRequest.put('user/updatepassword', data);
};

export const resend = async (user) => {
  return publicRequest.post(`auth/send-confirmation`, user);
};

export const deleteUser = async (id) => {
  return userRequest.delete(`user/${id}`);
};

export const updateUser = async (user) => {
  return userRequest.put(`user/profil`, user);
};

export const changeProfil = async (user) => {
  return userRequest.post(`user/changeprofil`, user);
};

export const changeUser = async (user) => {
  return userRequest.put(`user/changeprofil`, user);
};

export const verifyDocuments = async (data) => {
  return userRequest.get(`auth/verifycompletedoc`, data);
};

export const sendUploadDocuments = async (data) => {
  return userRequest.post(`user/completedoc`, data);
};

export const uploadDocuments = async (data) => {
  return userRequest.put(`user/completedoc`, data);
};

export const getArticles = async (id) => {
  return publicRequest.get(`collect/collectsactualites/${id}`);
};

export const createArticles = async (data) => {
  return userRequest.post(`user/actualite/actualite`, data);
};

export const updateArticles = async (data) => {
  return userRequest.put(`user/actualite/actualite`, data);
};

export const deleteArticle = async (id) => {
  return userRequest.delete(`user/actualite/actualite/${id}`);
};

export const get = async () => {
  return userRequest.post('auth/me');
};

export const getUserRole = async () => {
  return publicRequest.get('roles/user');
};

export const getMyProfile = async () => {
  return userRequest.get(`auth/me`);
};

export const sendForm = async (data) => {
  return publicRequest.post(`collect/contact`, data);
};

export const getSupporters = async () => {
  return userRequest.get(`user/listesupporter`);
};

export const getStats = async () => {
  return userRequest.get(`user/dashboard`);
};

export const getAnalyticStats = async (data) => {
  return userRequest.get(`user/graphe`, data);
};

// Donation

export const makeAGift = async (id, data) => {
  return userRequest.post(`donation/donation/${id}`, data);
};
