/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { getMyProfile, updateUser } from 'api/user';
import countries from 'assets/countries.json';
import Header from 'components/Header';
import IconCheckCircle from 'components/icons/IconCheckCircle';
import IconTrash from 'components/icons/IconTrash';
import SubHeader from 'components/SubHeader';
import { fr } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useOutsideClick from 'utils/useOutsideClick';
import * as Yup from 'yup';

const PublicInformationSchema = Yup.object().shape({
  publicName: Yup.string().required('Vous devez renseigner un nom public'),
  description: Yup.string(),
});
const PrivateInformationSchema = Yup.object().shape({
  lastName: Yup.string().required('Veuillez saisir votre nom'),
  firstName: Yup.string().required('Veuillez saisir votre prénom'),
  birthDate: Yup.string().required('Veuillez saisir votre date de naissance'),
  email: Yup.string()
    .email("L'adresse email n'est pas valide")
    .required('Veuillez saisir votre adresse email'),
  address: Yup.string().required('Veuillez saisir votre adresse'),
  country: Yup.string().required('Choisissez votre pays'),
  city: Yup.string().required('Choisissez votre ville'),
  zipCode: Yup.string().required('Renseignez votre code postal'),
});

const UpdatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(
    'Veuillez entrer votre ancien mot de passe'
  ),
  newPassword: Yup.string()
    .required('Veuillez entrer un nouveau mot de passe')
    .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
    .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une majuscule')
    .matches(
      /[+=!@#$%^&*]/,
      'Le mot de passe doit contenir au moins un caractère spécial'
    ),
  confirmPassword: Yup.string()
    .required('Veuillez confirmer le nouveau mot de passe')
    .oneOf(
      [Yup.ref('newPassword'), null],
      'Les mots de passe ne correspondent pas'
    ),
});

const IAmAnIndividualInformationSchema = Yup.object().shape({
  cni: Yup.mixed(),
  license: Yup.mixed(),
  passport: Yup.mixed(),
  residence_permit: Yup.mixed(),
});

const IAmAnAssociationInformationSchema = Yup.object().shape({
  association_cni: Yup.mixed(),
  status: Yup.mixed(),
  receipt: Yup.mixed(),
  joafe: Yup.mixed(),
});

const IAmAnEnterpriseInformationSchema = Yup.object().shape({
  company_cni: Yup.mixed(),
  company_status: Yup.mixed(),
  Kbis: Yup.mixed(),
  siren: Yup.string(),
});

const CERTIFICATE = {
  individual: [
    {
      file: 'cni',
      name: 'Carte d’identité',
    },
    {
      file: 'license',
      name: 'Permis',
    },
    {
      file: 'passport',
      name: 'Passeport',
    },
    {
      file: 'residence_permit',
      name: 'Titre de séjour',
    },
  ],
  association: [
    {
      file: 'association_cni',
      name: 'Carte d’identité',
    },
    {
      file: 'status',
      name: 'Status à jour',
    },
    {
      file: 'receipt',
      name: 'Récépissé de la préfecture',
    },
    {
      file: 'joafe',
      name: 'JOAFE',
    },
  ],
  company: [
    {
      file: 'company_cni',
      name: 'Carte d’identité',
    },
    {
      file: 'company_status',
      name: 'Status à jour',
    },
    {
      file: 'Kbis',
      name: 'Kbis de moins 3 mois',
    },
  ],
};

const MyProfile = () => {
  const navigate = useNavigate();
  const publicformRef = useRef(null);
  const privateformRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingPassword, setLoadingPassword] = useState('');
  const [loadingProfile, setLoadingProfile] = useState('');
  const [loadingPrivate, setLoadingPrivate] = useState('');
  const [birthDate, setBirthDate] = useState();
  const [profile, setProfile] = useState();
  const [message, setMessage] = useState({
    public: [],
    private: [],
  });
  const [passwordMessage, setPasswordMessage] = useState([]);
  const [profileMessage, setProfileMessage] = useState([]);
  const [privateMessage, setPrivateMessage] = useState([]);
  const [individualCertificateIndex, setIndividualCertificateIndex] =
    useState(0);
  const [associationCertificateIndex, setAssociationCertificateIndex] =
    useState(0);
  const [companyCertificateIndex, setCompanyCertificateIndex] = useState(0);
  const [userData, setUserData] = useState(null);

  const redirect = () => {
    const location = window.location.href;
    setTimeout(() => {
      navigate(`/se-connecter?redirect=${location}`);
    }, 500);
  };

  const openCalendar = () => {
    setShowCalendar(true);
  };

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const calendarDateRef = useOutsideClick(closeCalendar);

  const logOut = () => {
    localStorage.removeItem('persist:root');
    redirect();
  };

  const getUser = async () => {
    getMyProfile()
      .then((res) => {
        setLoadingData(false);
        setUserData(res.data.user.original.data);
        const user = res.data.user.original.data;

        setTimeout(() => {
          publicformRef.current.setValues({
            publicName: user.public_name || '',
            description: user.description || '',
          });

          privateformRef.current.setValues({
            lastName: user.surname || '',
            firstName: user.name || '',
            birthDate: user?.date_nais ? new Date(user.date_nais) : '',
            email: user.email || '',
            address: user.adress || '',
            country: user.state || '',
            city: user.city || '',
            zipCode: user.postal || '',
          });
        }, 500);
      })
      .catch((err) => {
        setLoadingData(false);
        if (err?.response?.status === 401) {
          logOut();
        }
      });
  };

  const formikField = (field) => {
    if (field.type === 'select') {
      if (field.hasValidation) {
        return (
          <Field
            as="select"
            name={field.name}
            className="w-full rounded border border-gray-300 bg-white p-3.5"
          >
            {field.listOptions.map((option) => {
              return <option value={option.value}>{option.name}</option>;
            })}
          </Field>
        );
      }
      return (
        <Field
          as="select"
          name={field.name}
          className={`relative w-full rounded border border-gray-300 bg-white p-3.5
          ${field.disabled ? 'bg-gray-200 select-none' : ''}`}
          disabled={field.disabled}
        >
          {field.listOptions.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </Field>
      );
    }
    if (field.hasValidation) {
      if (field.type === 'textarea') {
        return (
          <Field
            name={field.name}
            as="textarea"
            placeholder={field.placeholder}
            className={`relative w-full rounded border border-gray-300 bg-white p-3.5
            ${field.disabled ? 'bg-gray-200 select-none' : ''}`}
            disabled={field.disabled}
          />
        );
      }
      return (
        <Field
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          className={`relative w-full rounded border border-gray-300 bg-white p-3.5
          ${field.disabled ? 'bg-gray-200 select-none' : ''}`}
          validate={field.validate}
          disabled={field.disabled}
        />
      );
    }
    if (field.type === 'textarea') {
      return (
        <Field
          as="textarea"
          name={field.name}
          rows="6"
          placeholder={field.placeholder}
          disabled={field.disabled}
          className={`relative w-full rounded border border-gray-300 bg-white p-3.5
          ${field.disabled ? 'bg-gray-200 select-none' : ''}`}
        />
      );
    }
    return (
      <div
        className="relative"
        ref={() => field.name === 'birthDate' && calendarDateRef}
      >
        <Field
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          disabled={field.disabled}
          className={`relative w-full rounded border border-gray-300 bg-white p-3.5
          ${field.disabled ? 'bg-gray-200 select-none' : ''}`}
          onClick={() => field.name === 'birthDate' && openCalendar()}
        />

        {showCalendar && field.name === 'birthDate' && (
          <Calendar
            className="rounded shadow-md shadow-slate-200 sm:left-0 sm:top-full sm:z-10 lg:absolute"
            onShownDateChange={(date) => {
              const currentDate = new Date(date);
              const currentBirthDate = new Date(date);
              // set birthDate to current date
              currentDate.setDate(birthDate.getDate() + 1); // set birthDate to current date
              currentBirthDate.setDate(birthDate.getDate());
              privateformRef.current.setFieldValue(
                'birthDate',
                currentDate.toISOString().split('T')[0]
              );
              setShowCalendar(false);
            }}
            onChange={(date) => {
              const currentDate = new Date(date);
              // add 1 day to current date
              currentDate.setDate(currentDate.getDate() + 1);
              privateformRef.current.setFieldValue(
                'birthDate',
                currentDate.toISOString().split('T')[0]
              );
              setShowCalendar(false);
            }}
            locale={fr}
            color="#FD6E56B7"
          />
        )}
      </div>
    );
  };

  const INFORMATIONS = {
    public: {
      profile: [
        {
          name: 'publicName',
          type: 'text',
          title: 'Nom public',
          placeholder: 'Kuadra',
          hasValidation: false,
          semiWidth: false,
        },
        {
          name: 'description',
          type: 'textarea',
          title: 'Bio',
          placeholder: 'Quelques mots sur vous...',
          hasValidation: false,
          semiWidth: false,
        },
      ],
      updatePassword: [
        {
          name: 'oldPassword',
          type: 'password',
          title: 'Ancien mot de passe',
          placeholder: '',
          hasValidation: false,
          semiWidth: false,
        },
        {
          name: 'newPassword',
          type: 'password',
          title: 'Nouveau mot de passe',
          placeholder: '',
          hasValidation: true,
          semiWidth: false,
        },
        {
          name: 'confirmPassword',
          type: 'password',
          title: 'Confirmez votre mot de passe',
          placeholder: '',
          hasValidation: true,
          semiWidth: false,
        },
      ],
    },
    private: [
      {
        name: 'lastName',
        type: 'text',
        title: 'Nom',
        placeholder: 'Angel',
        hasValidation: true,
        semiWidth: true,
        disabled: true,
      },
      {
        name: 'firstName',
        type: 'text',
        title: 'Prenom',
        placeholder: 'Melissa',
        hasValidation: false,
        semiWidth: true,
        disabled: true,
      },
      {
        name: 'birthDate',
        type: 'date',
        title: 'Date de naissance',
        hasValidation: false,
        semiWidth: false,
        disabled: false,
      },
      {
        name: 'email',
        type: 'email',
        title: 'Adresse email',
        placeholder: '',
        hasValidation: false,
        semiWidth: false,
        disabled: true,
      },
      {
        name: 'address',
        type: 'text',
        title: 'Numéro et nom de la voie',
        placeholder: '',
        hasValidation: false,
        semiWidth: false,
        disabled: false,
      },
      {
        name: 'country',
        type: 'select',
        title: 'Pays',
        listOptions: countries.fr,
        placeholder: '',
        hasValidation: false,
        semiWidth: false,
        disabled: false,
      },
      {
        name: 'city',
        type: 'text',
        title: 'Ville',
        placeholder: '',
        hasValidation: false,
        semiWidth: true,
        disabled: false,
      },
      {
        name: 'zipCode',
        type: 'text',
        title: 'Code postal',
        placeholder: '10178',
        hasValidation: false,
        semiWidth: true,
        disabled: false,
      },
    ],
  };

  const updatePassword = (oldPassword, newPassword, resetForm) => {
    setLoadingPassword(true);
    updateUser({
      oldpassword: oldPassword,
      password: newPassword,
    })
      .then(() => {
        setPasswordMessage([
          true,
          'Votre mot de passe a bien été réinitialisé',
        ]);
        setLoadingPassword(false);
        resetForm();
      })
      .catch((err) => {
        if (err.response.status) {
          setPasswordMessage([false, 'Ancien mot de passe incorrect']);
        } else {
          setPasswordMessage([
            false,
            'Une erreur s’est produite. Veuillez réessayer.',
          ]);
        }
        setLoadingPassword(false);
      });
  };

  const updateProfile = (profileInformation) => {
    setLoadingProfile(true);
    updateUser(profileInformation)
      .then(() => {
        setProfileMessage([true, 'Votre profil a bien été mis à jour']);
        setLoadingProfile(false);
      })
      .catch(() => {
        setProfileMessage([
          false,
          'Une erreur s’est produite. Veuillez réessayer.',
        ]);
        setLoadingProfile(false);
      });
  };

  const updatePersonalData = (data) => {
    setLoadingPrivate(true);
    updateUser(data)
      .then(() => {
        setPrivateMessage([
          true,
          'Vos données personnelles ont bien été mis à jour',
        ]);
        setLoadingPrivate(false);
      })
      .catch(() => {
        setPrivateMessage([
          false,
          'Une erreur s’est produite. Veuillez réessayer.',
        ]);
        setLoadingPrivate(false);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Mon profil - KOUDPOUSS" />
      <Header />
      <section className="bg-white px-6 pt-6 pb-20">
        {/* Menus */}
        <SubHeader currentPage={1} />

        {loadingData && (
          <div className="relative text-center">
            <div
              className={`mx-auto text-center transition-colors delay-300
                ${loadingData ? 'text-primary' : 'text-transparent'}`}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-live="polite"
                aria-busy="true"
                aria-labelledby="title-08a desc-08a"
                className="mx-auto h-16 w-16 md:h-24 md:w-24"
              >
                <title id="title-08a">loading</title>
                <path
                  d="M7 8H3V16H7V8Z"
                  className="animate animate-bounce fill-current "
                />
                <path
                  d="M14 8H10V16H14V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.2s]"
                />
                <path
                  d="M21 8H17V16H21V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.4s]"
                />
              </svg>
            </div>
          </div>
        )}

        {/* Forms */}
        {!loadingData && (
          <section className="w-full gap-y-4 lg:grid lg:grid-cols-2 lg:gap-5">
            {/* Public information */}
            <div className="relative h-full w-full">
              <Formik
                innerRef={publicformRef}
                initialValues={{
                  publicName: '',
                  description: '',
                }}
                validationSchema={PublicInformationSchema}
                onSubmit={(values) => {
                  setProfileMessage([]);

                  setLoading(true);
                  setMessage({
                    public: [],
                    private: [],
                  });

                  updateProfile({
                    public_name: values.publicName,
                    biography: values.description,
                  });
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form className="h-full">
                    <div className="min-h-full border border-gray-200 bg-white p-10 shadow-md">
                      <h2 className="mb-6 text-center text-2xl text-primary">
                        Profil
                      </h2>
                      <figure className="mb-4 flex items-center">
                        {userData?.surname && userData?.name && !profile && (
                          <span className="inline-flex h-[3.875rem] w-[3.875rem] items-center justify-center rounded-full bg-gray-600">
                            <span className="text-lg font-medium leading-none text-white">
                              {userData.surname.charAt(0)}{' '}
                              {userData.name.charAt(0)}
                            </span>
                          </span>
                        )}

                        {profile && (
                          <span
                            className="mr-3 h-[3.875rem] w-[3.875rem] rounded-full bg-slate-200/50 bg-cover bg-no-repeat"
                            style={{
                              backgroundImage: `url(${profile})`,
                            }}
                          />
                        )}

                        <label
                          htmlFor="image"
                          className="my-6 block w-20 text-center"
                        >
                          <span className="ml-4 cursor-pointer rounded-md bg-gray-100 py-2 px-4 text-sm text-gray-500 hover:bg-gray-200">
                            Changer
                          </span>
                          <input
                            name="image"
                            type="file"
                            id="image"
                            accept="image/*"
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              setProfile(URL.createObjectURL(file));
                              setFieldValue('file', file);
                            }}
                            className="hidden w-full rounded border border-gray-300 bg-white p-3.5"
                          />
                        </label>
                      </figure>

                      <div className="profile flex flex-wrap justify-between gap-y-4">
                        {INFORMATIONS.public.profile.map((field) => {
                          return (
                            <div
                              key={`private_${field.name}`}
                              className={`flex flex-col px-2 ${
                                field.semiWidth ? 'sm:w-1/2' : 'w-full '
                              }`}
                            >
                              <div className="flex flex-col items-start justify-start space-y-1">
                                <h3 className="text-xl leading-normal text-gray-900">
                                  {[field.title]}
                                </h3>
                              </div>
                              <div className="">
                                {formikField(field)}
                                {errors[field.name] && touched[field.name] ? (
                                  <div className="text-red-400">
                                    {errors[field.name]}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {profileMessage.length > 0 && (
                        <div
                          className={`mb-4 text-center text-sm ${
                            profileMessage[0] ? 'text-primary' : 'text-red-500'
                          }`}
                        >
                          {profileMessage[1]}
                        </div>
                      )}
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className={`button-glassmorphism relative mt-10 w-fit items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${
                              !loadingProfile && 'duration-300 hover:scale-105'
                            }`}
                        >
                          {loadingProfile
                            ? 'En cours'
                            : 'Mettre à jour mon profil'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* Private information */}
            <div className="relative h-full w-full">
              <Formik
                innerRef={privateformRef}
                initialValues={{
                  lastName: '',
                  firstName: '',
                  birthDate: '',
                  email: '',
                  address: '',
                  country: '',
                  city: '',
                  zipCode: '',
                }}
                validationSchema={PrivateInformationSchema}
                onSubmit={(values) => {
                  setPrivateMessage([]);
                  if (!loadingPrivate) {
                    const formData = new FormData();
                    Object.keys(values).forEach((key) => {
                      formData.append(key, values[key]);
                    });
                    updatePersonalData({
                      surname: values.lastName,
                      name: values.firstName,
                      email: values.email,
                      date_nais: values.birthDate,
                      city: values.city,
                      adress: values.address,
                      postal: values.zipCode,
                      state: values.country,
                    });
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form className="h-full">
                    <div className="min-h-full border border-gray-200 bg-white p-10 shadow-md">
                      <h2 className="mb-6 text-center text-2xl text-primary">
                        Informations personnelles
                      </h2>

                      <p className="mb-4 text-left text-sm text-gray-500">
                        Si vous souhaitez changer votre nom, prénom ou même
                        votre adresse email, merci de nous contacter à
                        l&apos;adresse suivante :{' '}
                        <a
                          href="mailto:pole-securite@koud-pouss.com
                          "
                          className="text-primary"
                        >
                          pole-securite@koud-pouss.com
                        </a>
                        <br />
                        <br />
                        Pourquoi je ne peux pas le faire moi-même ? Pour en
                        savoir plus,{' '}
                        <a
                          href="/faq"
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary"
                        >
                          cliquez ici
                        </a>
                      </p>
                      <div className="flex flex-wrap justify-between gap-y-4">
                        {INFORMATIONS.private.map((field) => {
                          return (
                            <div
                              key={`private${field.name}`}
                              className={`flex flex-col px-2 ${
                                field.semiWidth ? 'sm:w-1/2' : 'w-full '
                              }`}
                            >
                              <div className="flex flex-col items-start justify-start space-y-1">
                                <h3 className="text-xl leading-normal text-gray-900">
                                  {[field.title]}
                                </h3>
                              </div>
                              <div className="">
                                {formikField(field)}
                                {errors[field.name] && touched[field.name] ? (
                                  <div className="text-red-400">
                                    {errors[field.name]}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {privateMessage.length > 0 && (
                        <div
                          className={`my-4 text-center text-sm ${
                            privateMessage[0] ? 'text-primary' : 'text-red-500'
                          }`}
                        >
                          {privateMessage[1]}
                        </div>
                      )}
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className={`button-glassmorphism relative mt-10 w-fit items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${
                              !loadingPrivate && 'duration-300 hover:scale-105'
                            }`}
                        >
                          {loadingPrivate
                            ? 'En cours'
                            : 'Mettre à jour les données personnelles'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* Update Password */}
            <div className="relative h-full w-full">
              <Formik
                initialValues={{
                  oldPassword: '',
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={UpdatePasswordSchema}
                onSubmit={(values, { resetForm }) => {
                  setPasswordMessage([]);
                  if (!loadingPassword) {
                    updatePassword(
                      values.oldPassword,
                      values.newPassword,
                      resetForm
                    );
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form className="h-full">
                    <div className="min-h-full border border-gray-200 bg-white p-10 shadow-md">
                      <h2 className="mb-6 text-center text-2xl text-primary">
                        Modifier mot de passe
                      </h2>
                      <div className="flex flex-wrap justify-between gap-y-4">
                        {INFORMATIONS.public.updatePassword.map((field) => {
                          return (
                            <div
                              key={`private_${field.name}`}
                              className={`flex flex-col px-2 ${
                                field.semiWidth ? 'sm:w-1/2' : 'w-full '
                              }`}
                            >
                              <div className="flex flex-col items-start justify-start space-y-1">
                                <h3 className="text-xl leading-normal text-gray-900">
                                  {[field.title]}
                                </h3>
                              </div>
                              <div className="">
                                {formikField(field)}
                                {errors[field.name] && touched[field.name] ? (
                                  <div className="text-red-400">
                                    {errors[field.name]}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {passwordMessage.length > 0 && (
                        <div
                          className={`mb-4 text-center text-sm ${
                            passwordMessage[0] ? 'text-primary' : 'text-red-500'
                          }`}
                        >
                          {passwordMessage[1]}
                        </div>
                      )}
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className={`button-glassmorphism relative mt-10 w-fit items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${
                              !loadingPassword && 'duration-300 hover:scale-105'
                            }`}
                        >
                          {loadingPassword ? 'En cours' : 'Valider'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* Call to actions buttons */}
            <div className="relative h-full w-full">
              <div className="h-full">
                <div className="min-h-full border border-gray-200 bg-white p-10 shadow-md">
                  <h2 className="mb-6 text-center text-2xl text-primary">
                    Certifier son compte
                  </h2>
                  {message.private.length > 0 && (
                    <div
                      className={`text-center text-sm ${
                        message.public[0] ? 'text-green-500' : 'text-secondary'
                      }`}
                    >
                      {message.public[1]}
                    </div>
                  )}
                  <ul className="mx-auto mt-4 max-w-sm space-y-10 sm:px-10">
                    <li>
                      <button
                        type="submit"
                        data-hs-overlay="#i-am-an-individual"
                        className={`button-glassmorphism relative mx-auto w-full items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${!loading && 'duration-300 hover:scale-105'}`}
                      >
                        Je suis un particulier
                        <svg
                          className="h-8 w-8 animate-spin text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button
                        type="submit"
                        data-hs-overlay="#i-am-an-association"
                        className={`button-glassmorphism relative mx-auto w-full items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${!loading && 'duration-300 hover:scale-105'}`}
                      >
                        Je suis une entreprise
                        <svg
                          className="h-8 w-8 animate-spin text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button
                        type="submit"
                        data-hs-overlay="#i-am-a-company"
                        className={`button-glassmorphism relative mx-auto w-full items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${!loading && 'duration-300 hover:scale-105'}`}
                      >
                        Je suis une association
                        <svg
                          className="h-8 w-8 animate-spin text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                  <p className="mt-10 text-center text-xs">
                    <span className="text-red-600">Nb </span>: Vous disposez
                    d’un délai de 90 jours à partir de la date de création de
                    votre collecte pour certifier votre compte. La non
                    certification du profil donnera lieu au remboursement des
                    dons reçus.
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}
        {!loadingData && (
          <div className="mt-4 w-full">
            <button
              type="button"
              className="ml-auto flex items-center leading-none"
            >
              <IconTrash color="#ff0000" />
              <span className="ml-3 mt-1">Supprimer mon-compte</span>
            </button>
          </div>
        )}

        {/* Verify */}
        {/* I am an individual */}
        <div
          id="i-am-an-individual"
          className="hs-overlay fixed top-0 left-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
        >
          <div className="m-3 opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="flex flex-col border bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:shadow-slate-700/[.7]">
              <Formik
                initialValues={{
                  cni: null,
                  license: null,
                  passport: null,
                  residence_permit: null,
                }}
                validationSchema={IAmAnIndividualInformationSchema}
                onSubmit={(values, { resetForm }) => {}}
              >
                {({ errors, touched, getFieldMeta }) => (
                  <Form>
                    <div className="flex items-center justify-end border-b py-3 px-4 dark:border-gray-700">
                      <button
                        type="button"
                        className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                        data-hs-overlay="#i-am-an-individual"
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-3.5 w-3.5"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="overflow-y-auto p-4">
                      <h2 className="mb-5 flex items-center justify-center text-2xl text-primary">
                        <span className="mr-5">Vérifier son profil </span>
                        <IconCheckCircle color="#058300" dimension="12px" />
                      </h2>
                      <p className="mt-1 text-center">
                        Importez votre justificatif d’identité en cours de
                        validité , en couleur et lisible . Aucune partie ne doit
                        être coupée.
                      </p>
                      <ul className="my-8 flex flex-col flex-wrap rounded border border-gray-300 px-5 pb-10 text-xs sm:flex-row">
                        {/* Id */}
                        <li
                          className={`mx-auto mt-10 flex w-full flex-col ${
                            individualCertificateIndex !== 0 && 'opacity-40'
                          } items-center sm:w-1/2 sm:pr-5`}
                        >
                          <button
                            type="submit"
                            onClick={() => setIndividualCertificateIndex(0)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Carte d’identité
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('cni').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                        {/* License */}
                        <li
                          className={`mx-auto mt-10 flex w-full flex-col ${
                            individualCertificateIndex !== 1 && 'opacity-40'
                          } sm:w-1/2 sm:pl-5`}
                        >
                          <button
                            type="submit"
                            onClick={() => setIndividualCertificateIndex(1)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Permis
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('license').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                        {/* Passport */}
                        <li
                          className={`mx-auto mt-10 flex w-full flex-col ${
                            individualCertificateIndex !== 2 && 'opacity-40'
                          } sm:w-1/2 sm:pr-5`}
                        >
                          <button
                            type="submit"
                            onClick={() => setIndividualCertificateIndex(2)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Passeport
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('passport').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                        {/* Residence permit */}
                        <li
                          className={`mx-auto mt-10 flex w-full flex-col ${
                            individualCertificateIndex !== 3 && 'opacity-40'
                          } sm:w-1/2 sm:pl-5`}
                        >
                          <button
                            type="submit"
                            onClick={() => setIndividualCertificateIndex(3)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Titre de séjour
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('residence_permit').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                      </ul>
                      <label
                        htmlFor={
                          CERTIFICATE.individual[individualCertificateIndex]
                            .file
                        }
                        className="mt-10 block w-full text-center"
                      >
                        Importez votre{' '}
                        {
                          CERTIFICATE.individual[individualCertificateIndex]
                            .name
                        }
                        <figure className="mt-5 rounded border border-gray-300 py-10 text-center">
                          <img
                            src="/assets/upload.png"
                            title="Justificatif"
                            alt="domicile"
                            className="mx-auto h-20"
                          />
                          <figcaption className="text-xs">
                            Importer une image
                          </figcaption>
                        </figure>
                        <Field
                          id={
                            CERTIFICATE.individual[individualCertificateIndex]
                              .file
                          }
                          hidden
                          name={
                            CERTIFICATE.individual[individualCertificateIndex]
                              .file
                          }
                          type="file"
                          className="w-full rounded border border-gray-300 bg-white p-3.5"
                        />
                      </label>
                      {errors[
                        CERTIFICATE.individual[individualCertificateIndex].file
                      ] &&
                      touched[
                        CERTIFICATE.individual[individualCertificateIndex].file
                      ] ? (
                        <div className="text-red-400">
                          {
                            errors[
                              CERTIFICATE.individual[individualCertificateIndex]
                                .file
                            ]
                          }
                        </div>
                      ) : null}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* I am an association */}
        <div
          id="i-am-an-association"
          className="hs-overlay fixed top-0 left-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
        >
          <div className="m-3 opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="flex flex-col border bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:shadow-slate-700/[.7]">
              <Formik
                initialValues={{
                  association_cni: null,
                  status: null,
                  receipt: null,
                  joafe: null,
                }}
                validationSchema={IAmAnAssociationInformationSchema}
                onSubmit={(values, { resetForm }) => {}}
              >
                {({ errors, touched, getFieldMeta }) => (
                  <Form>
                    <div className="flex items-center justify-end border-b py-3 px-4 dark:border-gray-700">
                      <button
                        type="button"
                        className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                        data-hs-overlay="#i-am-an-association"
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-3.5 w-3.5"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="overflow-y-auto p-4">
                      <h2 className="mb-5 flex items-center justify-center text-2xl text-primary">
                        <span className="mr-5">Vérifier son profil </span>
                        <IconCheckCircle color="#058300" dimension="12px" />
                      </h2>
                      <p className="mt-1 text-center">
                        Importez vos documents en cours de validité , en couleur
                        et lisible . Aucune partie ne doit être coupée.
                      </p>
                      <ul className="my-8 flex flex-col flex-wrap rounded border border-gray-300 px-5 pb-10 text-xs sm:flex-row">
                        {/* Id */}
                        <li
                          className={`mx-auto mt-10 flex w-full ${
                            associationCertificateIndex !== 0 && 'opacity-40'
                          }  flex-col items-center sm:w-1/2 sm:pr-5`}
                        >
                          <button
                            type="submit"
                            onClick={() => setAssociationCertificateIndex(0)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Carte d’identité{' '}
                            {getFieldMeta('cni_association').value}
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('cni').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                        {/* Updated status */}
                        <li
                          className={`mx-auto ${
                            associationCertificateIndex !== 1 && 'opacity-40'
                          } mt-10 flex w-full flex-col sm:w-1/2 sm:pl-5`}
                        >
                          <button
                            type="submit"
                            onClick={() => setAssociationCertificateIndex(1)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Status à jour
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('status').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                        {/* Receipt from the prefecture */}
                        <li
                          className={`mx-auto ${
                            associationCertificateIndex !== 2 && 'opacity-40'
                          } mt-10 flex w-full flex-col sm:w-1/2 sm:pr-5`}
                        >
                          <button
                            type="submit"
                            onClick={() => setAssociationCertificateIndex(2)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Récépissé de la préfecture
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('receipt').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                        {/* JOAFE */}
                        <li
                          className={`mx-auto mt-10 flex w-full flex-col justify-between sm:w-1/2 sm:pl-5 ${
                            associationCertificateIndex !== 3 && 'opacity-40'
                          }`}
                        >
                          <button
                            type="submit"
                            onClick={() => setAssociationCertificateIndex(3)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            JOAFE
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('joafe').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                      </ul>
                      <label
                        htmlFor={
                          CERTIFICATE.association[associationCertificateIndex]
                            .file
                        }
                        className="mt-10 block w-full text-center"
                      >
                        Importez votre{' '}
                        {
                          CERTIFICATE.association[associationCertificateIndex]
                            .name
                        }
                        <figure className="mt-5 rounded border border-gray-300 py-10 text-center">
                          <img
                            src="/assets/upload.png"
                            title="Justificatif"
                            alt="domicile"
                            className="mx-auto h-20"
                          />
                          <figcaption className="text-xs">
                            Importer une image
                          </figcaption>
                        </figure>
                        <Field
                          id={
                            CERTIFICATE.association[associationCertificateIndex]
                              .file
                          }
                          hidden
                          name={
                            CERTIFICATE.association[associationCertificateIndex]
                              .file
                          }
                          type="file"
                          className="w-full rounded border border-gray-300 bg-white p-3.5"
                        />
                      </label>
                      {errors[
                        CERTIFICATE.association[associationCertificateIndex]
                          .file
                      ] &&
                      touched[
                        CERTIFICATE.association[associationCertificateIndex]
                          .file
                      ] ? (
                        <div className="text-red-400">
                          {
                            errors[
                              CERTIFICATE.association[
                                associationCertificateIndex
                              ].file
                            ]
                          }
                        </div>
                      ) : null}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* I am a company */}
        <div
          id="i-am-a-company"
          className="hs-overlay fixed top-0 left-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
        >
          <div className="m-3 opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="flex flex-col border bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:shadow-slate-700/[.7]">
              <Formik
                initialValues={{
                  company_cni: null,
                  company_status: null,
                  Kbis: null,
                  siren: '',
                }}
                validationSchema={IAmAnEnterpriseInformationSchema}
                onSubmit={(values, { resetForm }) => {}}
              >
                {({ errors, touched, getFieldMeta }) => (
                  <Form>
                    <div className="flex items-center justify-end border-b py-3 px-4 dark:border-gray-700">
                      <button
                        type="button"
                        className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                        data-hs-overlay="#i-am-a-company"
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-3.5 w-3.5"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="overflow-y-auto p-4">
                      <h2 className="mb-5 flex items-center justify-center text-2xl text-primary">
                        <span className="mr-5">Vérifier son profil </span>
                        <IconCheckCircle color="#058300" dimension="12px" />
                      </h2>
                      <p className="mt-1 text-center">
                        Importez vos documents en cours de validité , en couleur
                        et lisible . Aucune partie ne doit être coupée.
                      </p>
                      <ul className="my-8 flex flex-col flex-wrap rounded border border-gray-300 px-5 pb-10 text-xs sm:flex-row">
                        {/* Id */}
                        <li
                          className={`${
                            companyCertificateIndex !== 0 && 'opacity-40'
                          } mx-auto mt-10 flex w-full flex-col items-center sm:w-1/2 sm:pr-5`}
                        >
                          <button
                            type="submit"
                            onClick={() => setCompanyCertificateIndex(0)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Carte d’identité
                          </button>
                          <span className="mt-3 text-red-500">Dirigeant*</span>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('company_cni').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                        {/* Updated status */}
                        <li
                          className={`${
                            companyCertificateIndex !== 1 && 'opacity-40'
                          } mx-auto mt-10 flex w-full flex-col justify-between sm:w-1/2 sm:pl-5`}
                        >
                          <button
                            type="submit"
                            onClick={() => setCompanyCertificateIndex(1)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Status à jour
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('company_status').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                        {/* Kbis */}
                        <li
                          className={`mx-auto mt-10 flex w-full flex-col sm:w-1/2 sm:pr-5 ${
                            companyCertificateIndex !== 2 && 'opacity-40'
                          }`}
                        >
                          <button
                            type="submit"
                            onClick={() => setCompanyCertificateIndex(2)}
                            className="button-glassmorphism relative w-full items-start justify-center rounded-full px-9 py-2 font-bold leading-tight text-white"
                          >
                            Kbis de moins 3 mois
                          </button>
                          {/* Options */}
                          <div className="mt-3 flex items-center justify-center rounded border border-gray-300 py-3 px-5">
                            <span className="mr-2">
                              {' '}
                              {getFieldMeta('kbis').value === null
                                ? 'En attente'
                                : 'Importez'}{' '}
                            </span>
                            <IconCheckCircle color="#058300" dimension="8px" />
                          </div>
                        </li>
                        {/* Siren number */}
                        <li className="mx-auto mt-10 flex w-full flex-col justify-end sm:w-1/2 sm:pl-5">
                          <label>
                            Numéro sirène
                            <Field
                              name="siren"
                              type="number"
                              className="mt-2 w-full rounded border border-gray-300 bg-white p-3.5"
                            />
                          </label>
                        </li>
                      </ul>
                      <label
                        htmlFor={
                          CERTIFICATE.company[companyCertificateIndex].file
                        }
                        className="mt-10 block w-full text-center"
                      >
                        Importez votre{' '}
                        {CERTIFICATE.company[companyCertificateIndex].name}
                        <figure className="mt-5 rounded border border-gray-300 py-10 text-center">
                          <img
                            src="/assets/upload.png"
                            title="Justificatif"
                            alt="domicile"
                            className="mx-auto h-20"
                          />
                          <figcaption className="text-xs">
                            Importer une image
                          </figcaption>
                        </figure>
                        <Field
                          id={CERTIFICATE.company[companyCertificateIndex].file}
                          hidden
                          name={
                            CERTIFICATE.company[companyCertificateIndex].file
                          }
                          type="file"
                          className="w-full rounded border border-gray-300 bg-white p-3.5"
                        />
                      </label>
                      {errors[
                        CERTIFICATE.company[companyCertificateIndex].file
                      ] &&
                      touched[
                        CERTIFICATE.company[companyCertificateIndex].file
                      ] ? (
                        <div className="text-red-400">
                          {
                            errors[
                              CERTIFICATE.company[companyCertificateIndex].file
                            ]
                          }
                        </div>
                      ) : null}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyProfile;
