import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const SubHeader = ({ currentPage }) => {
  return (
    <nav className="mb-16 hidden items-center justify-between py-7 px-6 shadow-md lg:block xl:flex">
      <ul className="shrink-0 sm:flex">
        <li className={`mr-5 shrink-0 ${currentPage === 0 && 'text-primary'}`}>
          <Link to="/tableau-de-bord">Tableau de bord</Link>
        </li>
        <li className={`shrink-0 ${currentPage === 1 && 'text-primary'}`}>
          <Link to="/mon-profil">Mon profil</Link>
        </li>
        <li
          className={`shrink-0 sm:mx-5 ${currentPage === 2 && 'text-primary'}`}
        >
          <Link to="/mes-collectes">Mes collectes</Link>
        </li>
        <li className={`shrink-0 ${currentPage === 3 && 'text-primary'}`}>
          <Link to="/mes-supporters">Mes supporteurs</Link>
        </li>
      </ul>
      <ul className="mt-10 flex-row flex-wrap justify-end sm:flex sm:space-x-4 xl:mt-0 2xl:grow">
        <li>
          <Link to="/historique-de-mes-virements">
            <button
              type="button"
              className="button-glassmorphism relative mx-auto w-1/2 items-start justify-center rounded-full px-4 py-4 text-sm font-bold leading-tight text-white duration-300 hover:scale-105 sm:px-9 lg:w-fit"
            >
              Mes virements
            </button>
          </Link>
        </li>
        <li className="mt-10 sm:mt-0">
          <Link to="/lancer-une-collecte">
            <button
              type="button"
              className="button-glassmorphism relative mx-auto w-fit items-start justify-center rounded-full px-4 py-4 text-sm font-bold leading-tight text-white duration-300 hover:scale-105 sm:px-9"
            >
              Lancer une nouvelle collecte
            </button>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
SubHeader.propTypes = {
  currentPage: PropTypes.number,
};
export default SubHeader;
