import React from 'react';

const IconGPay = () => {
  return (
    <svg
      width="25"
      height="11"
      viewBox="0 0 25 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5301 8.75809V5.73713H13.0893C13.7282 5.73713 14.2675 5.52305 14.7071 5.10083L14.8126 4.99379C15.6156 4.11962 15.5629 2.75781 14.7071 1.94905C14.2792 1.52088 13.6931 1.28896 13.0893 1.30085H10.5864V8.75809H11.5301ZM11.5303 4.82135V2.21666H13.1131C13.4531 2.21666 13.7756 2.34749 14.0159 2.58536C14.526 3.08489 14.5377 3.91744 14.0453 4.43481C13.8049 4.69052 13.4649 4.83324 13.1131 4.82135H11.5303ZM19.2147 4.05421C18.8102 3.67957 18.2592 3.48927 17.5617 3.48927C16.6649 3.48927 15.9908 3.82229 15.5453 4.48238L16.3777 5.01164C16.6825 4.55969 17.0987 4.33371 17.6262 4.33371C17.9603 4.33371 18.2827 4.45859 18.5347 4.68457C18.7809 4.89865 18.9216 5.20789 18.9216 5.53496V5.75499C18.5582 5.5528 18.101 5.44576 17.5383 5.44576C16.8818 5.44576 16.3542 5.60037 15.9615 5.91555C15.5688 6.23073 15.3695 6.647 15.3695 7.17626C15.3578 7.65795 15.5629 8.11585 15.9263 8.42508C16.2956 8.7581 16.7645 8.92461 17.3155 8.92461C17.9662 8.92461 18.482 8.63322 18.8747 8.05044H18.9157V8.7581H19.8184V5.61226C19.8184 4.95217 19.6191 4.42886 19.2147 4.05421ZM16.6533 7.81849C16.4599 7.67576 16.3426 7.44384 16.3426 7.19408C16.3426 6.91458 16.4716 6.68265 16.7237 6.4983C16.9816 6.31396 17.3041 6.21881 17.6851 6.21881C18.2128 6.21286 18.6231 6.3318 18.9163 6.56967C18.9163 6.97405 18.758 7.3249 18.4473 7.62224C18.1659 7.90769 17.7848 8.06825 17.3862 8.06825C17.1223 8.0742 16.8644 7.98499 16.6533 7.81849ZM21.8465 11L25 3.65579H23.9743L22.5147 7.31899H22.4972L21.0025 3.65579H19.9767L22.0458 8.43104L20.8735 11H21.8465Z"
        fill="#3C4043"
      />
      <path
        d="M8.27208 5.08296C8.27208 4.79157 8.24863 4.50018 8.20174 4.21474H4.22174V5.86199H6.50189C6.4081 6.39125 6.1033 6.86699 5.65782 7.16433V8.23475H7.0177C7.81488 7.4914 8.27208 6.39125 8.27208 5.08296Z"
        fill="#4285F4"
      />
      <path
        d="M4.22192 9.26947C5.35907 9.26947 6.32036 8.88888 7.01789 8.23473L5.65801 7.16432C5.277 7.42598 4.79049 7.57464 4.22192 7.57464C3.11995 7.57464 2.18796 6.81941 1.85385 5.80846H0.452942V6.91455C1.16805 8.35962 2.62758 9.26947 4.22192 9.26947Z"
        fill="#34A853"
      />
      <path
        d="M1.85401 5.80848C1.67814 5.27922 1.67814 4.70238 1.85401 4.16718V3.06702H0.452877C-0.150959 4.27422 -0.150959 5.70144 0.452877 6.90863L1.85401 5.80848Z"
        fill="#FBBC04"
      />
      <path
        d="M4.22192 2.40099C4.82566 2.38909 5.40596 2.62102 5.83971 3.04324L7.04719 1.81821C6.27933 1.0927 5.27114 0.694267 4.22192 0.706161C2.62758 0.706161 1.16805 1.62196 0.452942 3.06703L1.85385 4.17312C2.18796 3.15623 3.11995 2.40099 4.22192 2.40099Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default IconGPay;
