/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { getCollect } from 'api/collection';
import { getMyProfile, makeAGift } from 'api/user';
import Banner from 'components/Banner';
import Header from 'components/Header';
import IconArrowDown from 'components/icons/IconArrowDown';
import IconCreditCard from 'components/icons/IconCreditCard';
import IconGPay from 'components/icons/IconGPay';
import IconLock from 'components/icons/IconLock';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactInputMask from 'react-input-mask';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import {
  AMERICANEXPRESS,
  CARDARR,
  CARDICON,
  CVC,
  EXPIRYDATE,
  OTHERCARDS,
} from 'utils/constants';
import {
  minLength,
  stripeCardExpireValidation,
  stripeCardNumberValidation,
} from 'utils/validations';
import * as Yup from 'yup';

const PaymentInformationSchema = Yup.object().shape({
  amount: Yup.number()
    .min(1, 'Le montant doit être supérieur à 1€')
    .required('Veuillez entrer un montant'),
  koudpoussParticipation: Yup.number(),
  comment: Yup.string(),
  publicName: Yup.string().required(
    'Vous devez renseigner votre nom et prénom'
  ),
  email: Yup.string()
    .email("L'adresse email n'est pas valide")
    .required('Veuillez saisir votre adresse email'),
  hideIdentity: Yup.bool(),
  hasSelectedMethod: Yup.string().required(
    'Veuillez choisir une méthode de paiement'
  ),
  hideGift: Yup.bool(),
  creditCardType: Yup.string(),
  expiryDate: Yup.string(),
  cvc: Yup.number(),
  cardNumber: Yup.string(),
});

const reasonToGive = [
  {
    src: '/assets/help_twice.png',
    alt: "Il n'y a pas de petit geste, juste de grandes actions",
  },
  {
    src: '/assets/media_quality.webp',
    alt: "Chaque soutien est essentiel pour nous permettre de continuer d'exister et d'offrir la meilleure expérience de la collecte en ligne la plus solidaire. !",
  },
  {
    src: '/assets/thanks_for_donation.jpeg',
    alt: "Nous reversons une partie de vos soutiens à des organisations engagées et ça c'est nulle part ailleurs que sur l'koudpouss",
  },
  {
    src: '/assets/why_to_give.jpeg',
    alt: "Nous reversons une partie de vos soutiens à des organisations engagées et ça c'est nulle part ailleurs que sur l'koudpouss",
  },
];

const DEFAULT_LAKUASDRA_GIFT = [
  {
    donation: 10,
    value: 1,
  },
  {
    donation: 29,
    value: 1.8,
  },
  {
    donation: 39,
    value: 2.6,
  },
  {
    donation: 50,
    value: 3,
  },
  {
    donation: 69,
    value: 3.5,
  },
  {
    donation: 99,
    value: 3.8,
  },
  {
    donation: 149,
    value: 4.8,
  },
  {
    donation: 199,
    value: 6.6,
  },
  {
    donation: 399,
    value: 8,
  },
  {
    donation: 599,
    value: 12,
  },
  {
    donation: 999,
    value: 15,
  },
  {
    donation: 1499,
    value: 20,
  },
  {
    donation: 1999,
    value: 28,
  },
  {
    donation: 2999,
    value: 45,
  },
  {
    donation: 3999,
    value: 80,
  },
  {
    donation: 4999,
    value: 90,
  },
];

const default_Donation = (mainDonation) => {
  const anotherDonation = DEFAULT_LAKUASDRA_GIFT.find((index) => {
    return mainDonation <= index.donation;
  });
  return anotherDonation.value;
};

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [message, setMessage] = useState([]);
  const [collectionId, setCollectionId] = useState(null);
  const [creditCardSelected, setCreditCardSelected] = useState(CARDARR[0]);
  const slug = useLocation().pathname?.split('/')[2]?.toLowerCase();
  const [userData, setUserData] = useState(null);
  const formRef = useRef(null);
  const [enableToProcessMessage, setEnableToProcessMessage] = useState(null);

  const [slide, setSlide] = useState(0);
  const [percentGift, setPercentGift] = useState(1);

  const updateParticipation = (action) => {
    if (action === 'add' && percentGift < 30) {
      setPercentGift(percentGift + 1);
    } else if (action === 'reduce' && percentGift > 1) {
      setPercentGift(percentGift - 1);
    }
  };

  function findDebitCardType(cardNumber) {
    const regexPattern = {
      MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{2,}$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
      DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    };
    for (const card in regexPattern) {
      if (cardNumber.replace(/[^\d]/g, '').match(regexPattern[card]))
        return card;
    }
    return '';
  }

  const handleValidations = (type, value) => {
    let errorText;
    switch (type) {
      case 'cardNumber':
        if (value) {
          errorText = stripeCardNumberValidation(value);
          if (!errorText) {
            formRef.current.setFieldValue(
              'creditCardType',
              findDebitCardType(value)
            );
            setCreditCardSelected(findDebitCardType(value));
          }
          formRef.current.setFieldError('cardNumber', errorText);
        }
        break;
      case 'expiryDate':
        errorText =
          value === '' ? 'Required' : stripeCardExpireValidation(value);
        formRef.current.setFieldError('expiryDate', errorText);
        break;
      case 'cvc':
        errorText = value === '' ? 'Required' : minLength(3)(value);
        formRef.current.setFieldError('cvc', errorText);
        break;
      default:
        break;
    }
  };

  const handleBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };

  useEffect(() => {
    const range = [0, 1];
    const interval = setInterval(() => {
      setSlide(Math.floor(Math.random() * range.length));
    }, 2000);

    // disable mouse wheel on all inputs number
    document.querySelectorAll('input[type=number]').forEach((el) => {
      el.addEventListener('mousewheel', (e) => {
        e.preventDefault();
      });
    });
    return () => {
      clearInterval(interval);
      document.querySelectorAll('input[type=number]').forEach((el) => {
        el.removeEventListener('mousewheel', (e) => {
          e.preventDefault();
        });
      });
    };
  }, []);

  const anErrorAppear = () => {
    setLoading(false);
    setMessage([false, 'Une erreur est survenue, veuillez réessayer']);
    setLoadingData(false);
  };

  // MAKE A GIFT
  const sendDonation = async (donation, id) => {
    setLoading(true);
    makeAGift(collectionId || id, donation)
      .then((res) => {
        setLoadingData(false);
        setMessage([true, 'Don envoyé !!! Merci pour votre contribution.']);
        setLoading(false);
      })
      .catch((err) => {
        anErrorAppear();
      });
  };

  const fetchCollection = (withMakePayment, paymentData) => {
    if (!withMakePayment) setLoadingData(true);
    getCollect(slug)
      .then((res) => {
        if (res?.data?.data?.set_end_collect === 1) {
          setEnableToProcessMessage(
            <>
              <h1 className="mt-10 text-2xl font-bold">
                La collecte est terminée !
              </h1>
              <p className="mt-4">
                Cette collecte touche à sa fin, vous ne pouvez plus participer.
              </p>

              <br />
              <br />
              <Link
                to="/"
                className="relative mx-auto w-fit items-start justify-center rounded-full bg-primary/90 px-9 py-3 text-xs font-bold leading-tight text-white transition-colors duration-300 hover:scale-105 hover:bg-primary/100 sm:px-3 2xl:px-4"
              >
                Retourner à l&apos;accueil
              </Link>
            </>
          );
        }
        setCollectionId(res?.data?.data?.id);

        if (withMakePayment) {
          sendDonation(paymentData, res?.data?.data?.id);
        }

        if (!withMakePayment) setLoadingData(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setEnableToProcessMessage(
            <>
              <h1 className="mt-10 text-2xl font-bold">
                Nous n&apos;avons pas trouvé cette collecte.
              </h1>

              <p className="mt-4">
                Si vous avez reçu un lien pour participer à cette collecte,
                veuillez vérifier que vous avez bien copié l&apos;adresse
                complète.
              </p>

              <br />
              <br />

              <Link
                to="/"
                className="relative mx-auto w-fit items-start justify-center rounded-full bg-primary/90 px-9 py-3 text-xs font-bold leading-tight text-white transition-colors duration-300 hover:scale-105 hover:bg-primary/100 sm:px-3 2xl:px-4"
              >
                Retourner à l&apos;accueil
              </Link>
            </>
          );
        } else {
          setMessage([
            false,
            'Une erreur s’est produite. Veuillez recharger la page.',
          ]);
        }
        setLoadingData(false);
      });
  };

  const getUser = async () => {
    getMyProfile()
      .then((res) => {
        fetchCollection();
        setUserData(res.data.user.original.data);
        const user = res.data.user.original.data;
        setTimeout(() => {
          formRef.current.setValues({
            publicName: `${user.surname} ${user.name}`,
            email: user.email || '',
          });
        }, 500);
      })
      .catch(() => fetchCollection());
  };

  useEffect(() => {
    getUser();
  }, []);

  const selectPaymentMethod = (method, getFieldMeta, setFieldValue) => {
    setFieldValue(
      'hasSelectedMethod',
      getFieldMeta('hasSelectedMethod').value === method ? '' : method
    );
    setFieldValue(
      'paymentMethod',
      getFieldMeta('hasSelectedMethod').value === method ? 'gpay' : 'cb'
    );
  };

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Offrir un don - KOUDPOUSS" />
      {!enableToProcessMessage && (
        <div>
          <Banner
            image="happy-family.jpg"
            currentPage={1}
            headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
          />
          <div
            id="hs-static-backdrop-modal"
            className="hs-overlay duration fixed inset-0 top-0 left-0 h-full w-full
        overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-50 transition"
            style={{
              zIndex: loadingData ? 99 : -1,
              display: loadingData ? 'block' : 'none',
            }}
            data-hs-overlay-keyboard="false"
            data-hs-overlay-backdrop="static"
          >
            <div className="relative h-screen text-center">
              <div
                className={`m-auto text-center transition-colors delay-300 h-full
                ${loadingData ? 'text-primary' : 'text-transparent'}`}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-live="polite"
                  aria-busy="true"
                  aria-labelledby="title-08a desc-08a"
                  className="mx-auto h-full w-16 md:w-24"
                >
                  <title id="title-08a">loading</title>
                  <path
                    d="M7 8H3V16H7V8Z"
                    className="animate animate-bounce fill-current "
                  />
                  <path
                    d="M14 8H10V16H14V8Z"
                    className="animate animate-bounce fill-current [animation-delay:.2s]"
                  />
                  <path
                    d="M21 8H17V16H21V8Z"
                    className="animate animate-bounce fill-current [animation-delay:.4s]"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="bg-white pb-20 pt-10">
            <h1 className="mb-5 text-center text-xl font-medium text-primary">
              Je supporte avec un don
            </h1>
            <Formik
              innerRef={formRef}
              initialValues={{
                amount: location.state?.amount || '',
                koudpoussParticipation: 0,
                cardNumber: '',
                comment: '',
                publicName: '',
                email: '',
                hideIdentity: false,
                hideGift: false,
                creditCardType: CARDARR[0],
                expiryDate: '',
                cvc: '',
                hasSelectedMethod: '',
                paymentMethod: '',
              }}
              validationSchema={PaymentInformationSchema}
              onSubmit={(values, { setErrors, setFieldError }) => {
                const CARDNUMBER = values.cardNumber
                  .toString()
                  .split(' ')
                  .join('');

                if (values.hasSelectedMethod === 0) {
                  let errors = null;
                  if (values.expiryDate === '') {
                    errors = {
                      ...errors,
                      expiryDate: 'Veuillez entrez une date',
                    };
                  }
                  if (values.cvc === '') {
                    errors = {
                      ...errors,
                      cvc: 'Veuillez entrez le cvc',
                    };
                  } else if (values.cvc > 999) {
                    errors = {
                      ...errors,
                      cvc: 'Le CVC ne peut contenir que trois chiffres',
                    };
                  }
                  if (CARDNUMBER === '') {
                    errors = {
                      ...errors,
                      cardNumber: 'Veuillez entrez le numéro de la carte',
                    };
                  } else if (CARDNUMBER.length !== 16) {
                    errors = {
                      ...errors,
                      cardNumber: CARDNUMBER.length,
                    };
                  }
                  if (errors) {
                    setErrors(errors);
                  } else {
                    setLoading(true);
                    if (!loading) {
                      const EXPIRY_DATE = values.expiryDate.split('/');
                      const data = {
                        first_name: values.publicName,
                        last_name: values.publicName,
                        email: values.email,
                        commentary: values.comment,
                        mask_identity: values.hideIdentity,
                        mask_amount: values.hideGift,
                        card_no: CARDNUMBER,
                        expiry_month: EXPIRY_DATE[0],
                        expiry_year: `20${EXPIRY_DATE[1]}`,
                        cvv: values.cvc,
                        amount: values.amount,
                        payment_method: values.paymentMethod,
                        pourboire: values.koudpoussParticipation,
                      };

                      if (!collectionId) {
                        fetchCollection(true, data);
                      } else {
                        sendDonation(data);
                      }
                    }
                  }
                } else {
                  setFieldError(
                    'hasSelectedMethod',
                    'Non disponible. Veuillez sélectionner une autre option'
                  );
                }
              }}
            >
              {({ errors, touched, setFieldValue, getFieldMeta }) => (
                <Form>
                  {/* Contribute to the collection */}
                  <div className="mx-auto flex w-full flex-col space-y-4 px-6 sm:max-w-3xl sm:px-10">
                    {/* Default gift amount */}
                    {location.state?.amount && (
                      <span
                        type="button"
                        className="mx-auto w-fit rounded-full border border-primary py-1 px-5 font-bold text-red-500"
                      >
                        {location.state?.amount} €
                      </span>
                    )}
                    {/* Gift amount */}
                    <Field
                      name="amount"
                      type="number"
                      placeholder="Je choisis le montant de mon don"
                      className="w-full rounded border border-black bg-white px-3 py-1"
                      onBlur={() => {
                        let error;
                        setFieldValue(
                          'koudpoussParticipation',
                          default_Donation(formRef.current.values.amount || 0)
                        );
                        return error;
                      }}
                    />
                    {errors.amount && touched.amount ? (
                      <div className="text-red-400">{errors.amount}</div>
                    ) : null}
                    {/* Gift amount */}
                    <label htmlFor="comment" className="block pt-5 text-center">
                      <span>Laisser un commentaire</span>
                      <Field
                        name="comment"
                        id="comment"
                        as="textarea"
                        className="mx-auto mt-2 block w-full border p-4 sm:w-10/12"
                        rows="10"
                      />
                    </label>
                    {errors.comment && touched.comment ? (
                      <div className="text-red-400">{errors.comment}</div>
                    ) : null}
                    <h2 className="-mb-5 pt-3 text-center text-xl font-normal text-primary">
                      Vos informations
                    </h2>
                    {/* Name and Hide my identity */}
                    <fieldset className="flex flex-wrap justify-between">
                      {/* Name */}
                      <label className="mt-5">
                        <Field
                          name="publicName"
                          placeholder="Nom & prénom"
                          className="w-full max-w-sm rounded border px-2 py-1"
                        />
                        {errors.publicName && touched.publicName ? (
                          <div className="text-red-400">
                            {errors.publicName}
                          </div>
                        ) : null}
                      </label>
                      {/* Hide my identity */}
                      <label
                        className="mt-5 flex w-56 items-center justify-between"
                        htmlFor="hideIdentity"
                      >
                        Masquer mon identité
                        <div className="relative ml-5 inline-flex cursor-pointer items-center">
                          <Field
                            id="hideIdentity"
                            name="hideIdentity"
                            type="checkbox"
                            className="peer sr-only"
                            checked={getFieldMeta('hideIdentity').value}
                            onChange={() => {
                              setFieldValue(
                                'hideIdentity',
                                !getFieldMeta('hideIdentity').value
                              );
                              if (getFieldMeta('hideGift').value)
                                setFieldValue(
                                  'hideGift',
                                  !getFieldMeta('hideGift').value
                                );
                            }}
                          />
                          <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                        </div>
                      </label>
                    </fieldset>
                    {/* Email and hide my gift */}
                    <fieldset className="flex flex-wrap justify-between">
                      <label className="mt-5">
                        <Field
                          name="email"
                          type="email"
                          placeholder="Votre email"
                          className="w-full max-w-sm rounded border px-2 py-1"
                        />
                        {errors.email && touched.email ? (
                          <div className="text-red-400">{errors.email}</div>
                        ) : null}
                      </label>
                      {/* Hide my gift */}
                      <label
                        htmlFor="hideGift"
                        className="mt-5 flex w-56 items-center justify-between"
                      >
                        Masquer mon don
                        <div className="relative ml-5 inline-flex cursor-pointer items-center">
                          <Field
                            id="hideGift"
                            name="hideGift"
                            type="checkbox"
                            className="peer sr-only"
                            onChange={() => {
                              setFieldValue(
                                'hideGift',
                                !getFieldMeta('hideGift').value
                              );
                              if (getFieldMeta('hideIdentity').value)
                                setFieldValue(
                                  'hideIdentity',
                                  !getFieldMeta('hideIdentity').value
                                );
                            }}
                          />
                          <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                        </div>
                      </label>
                    </fieldset>
                    {/* Email */}
                    <h2 className="border-b-2 border-black pt-3 pb-5 text-center text-xl font-normal text-primary">
                      Votre Don
                    </h2>
                    <ul className="mx-auto w-10/12 text-lg">
                      <li className="mb-3 flex justify-between">
                        <span>Don</span>
                        <span className="font-medium text-primary">
                          {getFieldMeta('amount').value || 1} €
                        </span>
                      </li>
                      <li className="relative flex justify-between pb-7">
                        <span>
                          Votre participation au fonctionnement Koud Pouss
                        </span>
                        <span className="ml-3 shrink-0 font-medium text-primary">
                          {`${default_Donation(
                            getFieldMeta('amount').value || 0
                          )} €`}
                        </span>
                        <button
                          type="button"
                          data-hs-overlay="#i-contribute"
                          className="absolute bottom-0 font-medium text-primary"
                        >
                          Modifier
                        </button>
                      </li>
                      <li className="mt-8 flex justify-between border-t-2 border-t-black pt-5">
                        <strong>Total</strong>
                        <span className="font-medium text-primary">
                          {`${
                            Number(getFieldMeta('amount').value || 0) +
                            Number(
                              getFieldMeta('koudpoussParticipation').value || 0
                            )
                          } €`}
                        </span>
                      </li>
                    </ul>
                    <h2 className="border-b-2 border-b-black pt-3 pb-5 text-center text-xl font-normal text-primary">
                      Votre mode de paiement
                    </h2>
                    <div className="hs-accordion-group">
                      {/* CreditCard */}
                      <div
                        className="hs-accordion mb-4 !rounded-lg"
                        id="hs-basic-heading-one"
                      >
                        <button
                          type="button"
                          onClick={() =>
                            selectPaymentMethod(0, getFieldMeta, setFieldValue)
                          }
                          className="hs-accordion-toggle group mb-4 inline-flex w-full items-center gap-x-3 !rounded-lg border-2 border-gray-300 px-4 py-3 font-semibold hs-accordion-active:mb-4"
                          aria-controls="hs-basic-collapse-one"
                        >
                          <span className="flex h-8 w-8 items-center justify-center rounded-full border before:hidden before:h-5 before:w-5 before:rounded-full before:bg-primary before:hs-accordion-active:block" />
                          <IconCreditCard />
                          Carte crédit
                        </button>
                        <div
                          id="hs-basic-collapse-one"
                          className="hs-accordion-content -mt-4 hidden w-full overflow-hidden transition-[height] duration-300"
                          aria-labelledby="hs-basic-heading-one"
                        >
                          {/* creditCardType - expiryDate - cvc */}
                          <fieldset className="flex flex-wrap justify-between">
                            {/* creditCardType */}
                            <label className="md:1/2 mt-5 flex w-full flex-col items-start lg:w-2/5">
                              <span>Type de carte</span>
                              <div className="hs-dropdown relative inline-flex">
                                <button
                                  id="hs-dropdown-default"
                                  type="button"
                                  className="hs-dropdown-toggle mt-5 inline-flex items-center justify-center rounded border border-black py-2 pl-3 pr-12"
                                >
                                  <img
                                    src={CARDICON[creditCardSelected]}
                                    alt="card"
                                    width="42px"
                                    height="24px"
                                  />
                                  <span className="ml-4 mr-6">
                                    {creditCardSelected}
                                  </span>
                                  <IconArrowDown />
                                </button>

                                <ul
                                  className="hs-dropdown-menu z-10 mt-2 hidden w-56 rounded-lg bg-white p-2 opacity-0 shadow-md transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100"
                                  aria-labelledby="hs-dropdown-default"
                                >
                                  {CARDARR.map((option) => {
                                    return (
                                      <li className="flex items-center gap-x-3.5 rounded-md py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500">
                                        <button
                                          type="button"
                                          className="flex items-center space-x-2"
                                          onClick={() => {
                                            setFieldValue(
                                              'creditCardType',
                                              option
                                            );
                                            setCreditCardSelected(option);
                                          }}
                                        >
                                          <img
                                            src={CARDICON[option]}
                                            alt="card"
                                            width="42px"
                                            height="24px"
                                          />
                                          <span className="ml-3">{option}</span>
                                        </button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </label>
                            {/* expiryDate */}
                            <label className="mt-5 mr-5 flex flex-col lg:w-1/3">
                              Date d’expiration
                              <Field
                                name="expiryDate"
                                type="date"
                                render={({ field }) => (
                                  <ReactInputMask
                                    {...field}
                                    mask={EXPIRYDATE}
                                    className="mt-5 block rounded border border-black py-2 px-3"
                                    validationSchema={handleBlur}
                                  />
                                )}
                              />
                              {errors.expiryDate && touched.expiryDate ? (
                                <div className="text-red-400">
                                  {errors.expiryDate}
                                </div>
                              ) : null}
                            </label>
                            {/* cvc */}
                            <label className="mt-5 flex flex-col">
                              CVC
                              <Field
                                name="cvc"
                                type="number"
                                render={({ field }) => (
                                  <MaskedInput
                                    {...field}
                                    mask={CVC}
                                    guide={false}
                                    placeholderChar={'\u2000'}
                                    className="mt-5 block w-28 rounded border border-black py-2 px-3"
                                    onBlur={handleBlur}
                                  />
                                )}
                              />
                              {errors.cvc && touched.cvc ? (
                                <div className="text-red-400">{errors.cvc}</div>
                              ) : null}
                            </label>
                          </fieldset>
                          {/* cardNumber */}
                          <label className="mt-5 flex flex-col">
                            Numéro de carte
                            <Field
                              name="cardNumber"
                              type="number"
                              render={({ field }) => (
                                <div className="mt-5 flex w-full space-x-3 rounded border border-black py-2 px-3">
                                  <ReactInputMask
                                    {...field}
                                    mask={
                                      ['37', '34'].includes(
                                        field.value
                                          ?.split('')
                                          .splice(0, 2)
                                          .join('')
                                      )
                                        ? AMERICANEXPRESS
                                        : OTHERCARDS
                                    }
                                    onBlur={handleBlur}
                                  />
                                </div>
                              )}
                            />
                            {errors.cardNumber && touched.cardNumber ? (
                              <div className="text-red-400">
                                {errors.cardNumber}
                              </div>
                            ) : null}
                          </label>
                        </div>
                      </div>
                      <div
                        className="hs-accordion mb-4 !rounded-lg"
                        id="hs-basic-heading-two"
                      >
                        <button
                          type="button"
                          onClick={() =>
                            selectPaymentMethod(1, getFieldMeta, setFieldValue)
                          }
                          className="hs-accordion-toggle group mb-4 inline-flex w-full items-center gap-x-3 !rounded-lg border-2 border-gray-300 px-4 py-3 font-semibold hs-accordion-active:mb-9"
                          aria-controls="hs-basic-collapse-one"
                        >
                          <span className="flex h-8 w-8 items-center justify-center rounded-full border before:hidden before:h-5 before:w-5 before:rounded-full before:bg-primary before:hs-accordion-active:block" />
                          <IconGPay />
                          Gpay
                        </button>
                        <div
                          id="hs-basic-collapse-one"
                          className="hs-accordion-content -mt-4 hidden w-full overflow-hidden transition-[height] duration-300"
                          aria-labelledby="hs-basic-heading-two"
                        />
                      </div>
                    </div>
                    {errors.hasSelectedMethod && touched.hasSelectedMethod ? (
                      <div className="text-center text-red-400">
                        {errors.hasSelectedMethod}
                      </div>
                    ) : null}
                    {message.length > 0 && (
                      <div
                        className={`mb-4 text-center text-sm ${
                          message[0] ? 'text-green-500' : 'text-red-500'
                        }`}
                      >
                        {message[1]}
                      </div>
                    )}
                    <button
                      type="submit"
                      className={`relative shadow-lg w-10/12 min-w-fit mx-auto items-start justify-center rounded-lg bg-primary px-9 py-4 text-base font-bold leading-tight text-white
                          ${!loading && 'duration-300 hover:scale-105'}`}
                    >
                      {loading ? 'En cours' : 'Confirmer mon don'}
                    </button>
                    <figure className="mx-auto w-10/12 items-end sm:flex">
                      <span className="mt-7 flex shrink-0 justify-center text-primary sm:mb-1">
                        <IconLock />
                      </span>
                      <figcaption className="mt-7 text-xs sm:ml-5">
                        Toutes les informations bancaires pour traiter ce
                        paiement sont totalement sécurisées. Grâce au cryptage
                        SSL de vos données bancaires, vous êtes assurés de la
                        fiabilité de vos transactions
                      </figcaption>
                    </figure>
                  </div>
                  {/* Contribution for lakuadra */}
                  <div
                    id="i-contribute"
                    className="hs-overlay fixed top-0 left-0 z-[60] hidden h-full min-h-full w-full overflow-y-auto overflow-x-hidden px-5 pb-5 sm:px-10"
                  >
                    <div className="m-3 flex min-h-full items-center justify-center opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:my-10 sm:mx-auto sm:w-full sm:max-w-3xl">
                      <div className="flex flex-col border bg-white pt-3 pb-7 shadow-sm">
                        {/* Close */}
                        <div className="flex items-center justify-end border-b px-4 pb-3">
                          <button
                            type="button"
                            className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white"
                            data-hs-overlay="#i-contribute"
                          >
                            <span className="sr-only">Close</span>
                            <svg
                              className="h-3.5 w-3.5"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                        </div>
                        {/* Form */}
                        <section className="mt-3 flex items-center px-3">
                          {/* Why to make a gift */}
                          <figure className="hidden w-1/2 sm:block">
                            <img
                              src={reasonToGive[slide + 2].src}
                              alt={reasonToGive[slide + 2].alt}
                              title="Votre participation compte"
                            />
                          </figure>

                          {/* Heart rating */}
                          <ul className="relative ml-10 mr-10 hidden h-96 w-14 flex-col items-center justify-between font-bold text-primary sm:flex">
                            <li>30%</li>
                            <li className="absolute -left-28 top-[10rem] flex -rotate-90 flex-col items-center justify-center">
                              <input
                                className="custom-slider-bullet h-3 w-80 bg-primary"
                                type="range"
                                min="1"
                                max="30"
                                step="1"
                                value={percentGift}
                                onChange={(e) => {
                                  setPercentGift(e.target.value);
                                }}
                              />
                              <label className="mt-4 rotate-90">
                                {percentGift}% <br />{' '}
                                {`${
                                  (percentGift * getFieldMeta('amount').value) /
                                  100
                                } €`}
                              </label>
                            </li>
                            <li>{`${
                              (1 * getFieldMeta('amount').value) / 100
                            } €`}</li>
                          </ul>
                          {/* Make a gift */}
                          <aside className="mx-auto max-w-xs grow self-start text-center sm:grow-0">
                            {/* Reason */}
                            <figure className="flex justify-center">
                              <img
                                src={reasonToGive[slide].src}
                                alt={reasonToGive[slide].alt}
                                title="Votre participation compte"
                              />
                            </figure>
                            <span className="my-7 block font-bold text-primary">
                              Mon don <br />
                              {`${getFieldMeta('amount').value} €`}
                            </span>
                            {/* I contribute */}
                            <div className="mx-auto inline-flex flex-col">
                              <label className="block font-normal">
                                Je choisis le montant de ma participation
                              </label>
                              {/* trigger contribution */}
                              <div className="flex flex-col items-center justify-center text-primary sm:flex-row sm:justify-between">
                                {/* Add reduce contributions buttons */}
                                <fieldset className="mt-7 flex">
                                  <button
                                    type="button"
                                    onClick={() => updateParticipation('add')}
                                    className="border border-primary px-4"
                                  >
                                    +
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      updateParticipation('reduce')
                                    }
                                    className="ml-3 border border-primary px-4 py-1"
                                    aria-label="Réduire le montant de ma participation"
                                  >
                                    <span className="block w-3 border-t border-t-primary" />
                                  </button>
                                </fieldset>
                                {/* Amount to contribute */}
                                <span className="mt-7 border border-primary px-6 py-1">
                                  {`${
                                    (getFieldMeta('amount').value *
                                      percentGift) /
                                    100
                                  } €`}
                                </span>
                              </div>
                            </div>
                            <button
                              type="button"
                              data-hs-overlay="#i-contribute"
                              onClick={() =>
                                setFieldValue(
                                  'koudpoussParticipation',
                                  (getFieldMeta('amount').value * percentGift) /
                                    100
                                )
                              }
                              className="mt-7 block w-full rounded-lg bg-primary px-6 py-4 text-white"
                            >
                              Valider
                            </button>
                          </aside>
                        </section>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}

      {!loading && !loadingData && enableToProcessMessage && (
        <div>
          <Header />
          <div className="min-h-[40vh] w-full bg-white px-6 pt-6 pb-10 text-center">
            {enableToProcessMessage}
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
