/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import { getCategory } from 'api/category';
import {
  getCollect,
  getCollectAmount,
  getCollectSupporters,
  getCollectVotes,
} from 'api/collection';
import {
  createArticles,
  deleteArticle,
  get,
  getArticles,
  updateArticles,
} from 'api/user';
import CountDownTimer from 'components/Countdown';
import Header from 'components/Header';
import IconCheckCircle from 'components/icons/IconCheckCircle';
import IconSetting from 'components/icons/IconSetting';
import InternalEditor from 'components/InternalEditor';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const NewsInformationSchema = Yup.object().shape({
  title: Yup.string().required('Veuillez entrer le titre'),
  explanation: Yup.string().required('Veuillez complèter la description'),
});

const Collection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const slug = location.pathname?.split('/')[2]?.toLowerCase();
  const [collection, setCollection] = useState({});
  const [articles, setArticles] = useState({});
  const [description, setDescrption] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  const [message, setMessage] = useState([]);
  const [progress, setProgress] = useState(0);
  const [groupName, setGroupName] = useState('');
  const [likes, setLikes] = useState(0);
  const [supporters, setSupporters] = useState(0);
  const [color, setColor] = useState('');
  const [reached, setReached] = useState(0);
  const [articleToModify, setArticleToModify] = useState(null);
  const [amountRemainingToBeCollected, setAmountRemainingToBeCollected] =
    useState(0);

  const listSlides = [
    '/assets/little_piggy_bank.png',
    '/assets/pinky_piggy_bank.png',
  ];
  const collect = {
    name: 'Angel',
    verified: true,
    profile: '/assets/koudpouss_white.png',
    descriptions: [
      { img: '/assets/slideshow/sld2.jpg' },
      { img: '/assets/together.png' },
    ],
    articles: [{ img: '/assets/slideshow/sld2.jpg' }],
    supporters: [
      {
        profile: '/assets/profile_user.png',
        name: 'Angel',
        amount: '10 €',
        date: 'Il ya 5 jours',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vulputate sed mauris vitae pellentesque. Nunc ut ullamcorper libero. Aenean fringilla mauris quis risus laoreet interdum. Quisque imperdiet orci in metus aliquam egestas. Fusce elit libero, imperdiet nec orci quis, convallis hendrerit nisl. Cras auctor nec purus at placerat. Quisque consectetur, lectus in ullamcorper tempus, dolor arcu suscipit elit, id laoreet tortor justo nec arcu. Nam eu dictum ipsum. Integer feugiat est in tincidunt congue. Nam eget accumsan ligula. Nunc auctor ligula a quam fermentum, non iaculis diam suscipit. Aliquam lacinia lorem vel suscipit pulvinar. Etiam condimentum nunc non ultricies hendrerit. Sed nec blandit libero, ut gravida quam. Nam tortor est, faucibus at dolor id, blandit venenatis leo. Praesent euismod tempus libero et accumsan. Nunc ultrices sit amet urna sed euismod. Pellentesque finibus ipsum non mi sodales, vel ullamcorper ipsum pharetra. Praesent nec pharetra neque.',
      },
      {
        profile: '/assets/profile_user.png',
        name: 'Anonyme',
        amount: '20 €',
        date: 'Il ya 30 secondes',
        content: '',
      },
      {
        profile: '/assets/profile_user.png',
        name: 'Emilie Ines',
        amount: 'Don anonyme',
        date: 'Il ya 5 minutes',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vulputate sed mauris vitae pellentesque. Nunc ut ullamcorper libero. Aenean fringilla mauris quis risus laoreet interdum. Quisque imperdiet orci in metus aliquam egestas. Fusce elit libero, imperdiet nec orci quis, convallis hendrerit nisl. Cras auctor nec purus at placerat. Quisque consectetur, lectus in ullamcorper tempus, dolor arcu suscipit elit, id laoreet tortor justo nec arcu. Nam eu dictum ipsum. Integer feugiat est in tincidunt congue. Nam eget accumsan ligula. Nunc auctor ligula a quam fermentum, non iaculis diam suscipit. Aliquam lacinia lorem vel suscipit pulvinar. Etiam condimentum nunc non ultricies hendrerit. Sed nec blandit libero, ut gravida quam. Nam tortor est, faucibus at dolor id, blandit venenatis leo. Praesent euismod tempus libero et accumsan. Nunc ultrices sit amet urna sed euismod. Pellentesque finibus ipsum non mi sodales, vel ullamcorper ipsum pharetra. Praesent nec pharetra neque.',
      },
    ],
    // Données statiques des donations - À remplacer par des données dynamiques
    /* lastDonation: [
      {
        id: 1,
        profile: '/assets/profile_user.png',
        name: 'Angel',
        amount: '10 €',
        date: 'Il ya 5 jours',
      },
      {
        id: 2,
        profile: '/assets/profile_user.png',
        name: 'Bouba',
        amount: '10 €',
        date: 'Il ya 5 jours',
      },
      {
        id: 3,
        profile: '/assets/profile_user.png',
        name: 'Seydou',
        amount: '10 €',
        date: 'Il ya 5 jours',
      },
      {
        id: 4,
        profile: '/assets/profile_user.png',
        name: 'Yatma',
        amount: '10 €',
        date: 'Il ya 5 jours',
      },
    ], */
  };
  const [slide, setSlide] = useState(0);
  const [step, setStep] = useState(1);
  const modifyRef = useRef(null);

  const detailToDisplay = (index) => {
    setStep(index);
  };

  const redirect = () => {
    const newLocation = window.location.href;
    setTimeout(() => {
      navigate(`/se-connecter?redirect=${newLocation}`);
    }, 500);
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    redirect();
  };

  function calculateProgress(level, goal) {
    if (level > 0 && goal > 0) setProgress(parseInt((level / goal) * 100, 10));
  }

  const calculateTimeLeft = () => {
    // get last day of the current month
    const lastDay = dayjs(collection.ending_date)
      .add(1, 'second')
      .tz('Europe/Paris')
      .endOf('month')
      .format('YYYY-MM-DDT00:00:00Z');
    const difference = +new Date(lastDay) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const fetchCategory = (catId) => {
    getCategory(catId).then((res) => {
      setGroupName(res.data.data.title);
      setColor(res.data.data.color);
    });
  };

  const getAmount = async (id, goal) => {
    getCollectAmount(id).then((res) => {
      setAmountRemainingToBeCollected(
        `${
          goal && goal > 0 ? Number(goal) - Number(res.data.montant || 0) : 0
        } ${goal > 0 ? '€' : ''}`
      );
    });
  };

  const fetchVotes = (id) => {
    getCollectVotes(id).then((res) => {
      if (res.data.data?.vote) setLikes(res.data.data.vote);
    });
  };

  const fetchSupporters = (id) => {
    getCollectSupporters(id).then((res) => {
      if (res.data.data?.nbresupporter)
        setSupporters(res.data.data.nbresupporter);
    });
  };

  const getAllArticles = (collectionId) => {
    setLoading(true);
    getArticles(collectionId)
      .then((res) => {
        setLoading(false);
        setArticles({
          news: res?.data?.data,
          errorLoadingNews:
            res?.data?.data.length === 0
              ? [true, 'Aucune actualité disponible']
              : [false, ''],
        });
      })
      .catch((err) => {
        setArticles({
          errorLoadingNews: [
            true,
            'Une erreur s’est produite. Veuillez recharger la page.',
          ],
        });
        setLoading(false);
      });
  };

  const fetchCollection = () => {
    setLoading(true);
    getCollect(slug)
      .then((res) => {
        if (res?.data?.data?.set_end_collect === 1) {
          setMessage(
            <>
              <img
                src="/assets/collecte-terminé.webp"
                alt="not found"
                className="mx-auto max-w-xs"
              />
              <h1 className="mt-10 text-2xl font-bold">
                Cette collecte est terminée
              </h1>
              <p className="mt-4">
                Vous pouvez toujours soutenir d&apos;autres collectes.
              </p>

              <br />
              <br />
              <Link
                to="/toutes-les-collectes"
                className="relative mx-auto w-fit items-start justify-center rounded-full bg-primary/90 px-9 py-3 text-xs font-bold leading-tight text-white transition-colors duration-300 hover:scale-105 hover:bg-primary/100 sm:px-3 2xl:px-4"
              >
                Voir toutes les collectes
              </Link>
            </>
          );
          setLoading(false);
        } else {
          setCollection({
            id: res?.data?.data?.id,
            name: res?.data?.data?.title,
            categoryId: res.data.data?.category_id,
            amountGoal: res?.data?.data?.montant_cagnotte,
            description: res?.data?.data?.description,
            date: res?.data?.data?.created_at,
            ending_date: res?.data?.data?.end_date,
            hasFixedAmount: res?.data?.data?.montant_is_libre === 1,
            video:
              res?.data?.data?.url_video === 'null' ||
              res?.data?.data?.url_video === null
                ? ''
                : res?.data?.data?.url_video,
            userId: res?.data?.data?.users_id,
            publicName: res?.data?.data?.public_name,
            publicCollectedAmount:
              res?.data?.data?.is_public_collect_amount === 1,
            publicAmountGoal: res?.data?.data?.montant_is_libre === 1,
          });

          setImage(res?.data?.data.image);
          fetchCategory(res?.data?.data?.category_id);
          getAmount(res?.data?.data?.id, res?.data?.data?.montant_cagnotte);
          fetchVotes(res?.data?.data?.id);
          fetchSupporters(res?.data?.data?.id);
          getAllArticles(res?.data?.data?.id);
          setLoading(false);
          try {
            if (
              res?.data?.data?.description !== null &&
              res?.data?.data?.description !== 'null'
            )
              setDescrption(JSON.parse(res?.data?.data?.description));
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(
              '🚀 ~ file: collecte.js ~ line 247 ~ .then ~ error',
              error
            );
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setMessage(
            <>
              <img
                src="/assets/collecte-non-disponible.webp"
                alt="not found"
                className="mx-auto max-w-xs"
              />
              <h1 className="mt-10 text-2xl font-bold">
                Nous n&apos;avons pas trouvé cette collecte.
              </h1>

              <p className="mt-4">
                Malheureusement, cette collecte n&apos;existe pas ou a été
                supprimée.
              </p>

              <p className="mt-4">
                Vous pouvez toujours soutenir d&apos;autres collectes.
              </p>

              <br />
              <br />

              <Link
                to="/"
                className="relative mx-auto w-fit items-start justify-center rounded-full bg-primary/90 px-9 py-3 text-xs font-bold leading-tight text-white transition-colors duration-300 hover:scale-105 hover:bg-primary/100 sm:px-3 2xl:px-4"
              >
                Aller à l&apos;accueil
              </Link>
            </>
          );
        } else {
          setMessage([
            false,
            'Une erreur s’est produite. Veuillez recharger la page.',
          ]);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (collection?.id)
      get().then((res) => {
        if (res?.data?.user?.original?.data?.id === collection?.userId) {
          setIsOwner(true);
        }
      });
  }, [collection]);

  useEffect(() => {
    const range = [0, 1];
    const interval = setInterval(() => {
      setSlide(Math.floor(Math.random() * range.length));
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let timer = null;
    if (collection?.ending_date) {
      timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [collection?.ending_date]);

  useEffect(() => {
    if (slug) fetchCollection();
  }, [slug]);

  useEffect(() => {
    const percentageComplete = progress / 100;
    const strokeDashOffsetValue = 100 - percentageComplete * 100;
    const progressBar = document.querySelector('.profile-progress-bar');
    if (progressBar) {
      setTimeout(() => {
        progressBar.style.strokeDashoffset = strokeDashOffsetValue;
      }, 1000);
    }
  }, [progress]);

  const renderDate = (date) => {
    const dateNow = new Date();
    const dateCreated = new Date(date);

    // since n days if within a month
    if (dateNow.getMonth() === dateCreated.getMonth()) {
      const diff = dateNow.getDate() - dateCreated.getDate();
      if (diff === 0) return 'Lancé aujourd’hui';
      if (diff === 1) return 'Lancé hier';
      return `Lancé depuis ${diff} jours`;
    }
    if (dateNow.getFullYear() === dateCreated.getFullYear()) {
      const diff = dateNow.getMonth() - dateCreated.getMonth();
      if (diff === 1) return 'Lancé depuis 1 mois';
      return `Lancé depuis ${diff} mois`;
    }
    const diff = dateNow.getFullYear() - dateCreated.getFullYear();
    if (diff === 1) return 'Lancé il y a 1 an';
    return `Lancé il y a ${diff} ans`;
  };

  const openModifyPopup = (id) => {
    setArticleToModify(articles?.news[id]?.id);
    modifyRef.current.setValues({
      title: articles?.news[id]?.titre,
      explanation: articles?.news[id]?.content,
    });
    window.HSOverlay.open(document.getElementById('modify-article'));
  };

  const openDeletePopup = (id) => {
    setArticleToModify(id);
    window.HSOverlay.open(document.getElementById('delete_this_article'));
  };

  const deleteThisArticle = () => {
    if (!loading) {
      setLoading(true);
      setMessage([]);
      deleteArticle(articles.news[articleToModify].id)
        .then(() => {
          setMessage([true, 'Votre actualité a été supprimé']);
          getAllArticles(collection.id);
          setTimeout(() => {
            window.HSOverlay.close(
              document.getElementById('delete_this_article')
            );
            setMessage([]);
          }, 1500);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setMessage([false, 'Une erreur est survenue, veuillez réessayer']);
        });
    }
  };

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title={`${collection?.name || 'Collecte'} - KOUDPOUSS`} />

      <Header />
      <section className="min-h-[40vh] bg-white px-6 pb-10 pt-6">
        {loading && (
          <div className="relative text-center">
            <div
              className={`mx-auto text-center transition-colors delay-300
                ${loading ? 'text-primary' : 'text-transparent'}`}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-live="polite"
                aria-busy="true"
                aria-labelledby="title-08a desc-08a"
                className="mx-auto h-16 w-16 md:h-24 md:w-24"
              >
                <title id="title-08a">loading</title>
                <path
                  d="M7 8H3V16H7V8Z"
                  className="animate animate-bounce fill-current "
                />
                <path
                  d="M14 8H10V16H14V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.2s]"
                />
                <path
                  d="M21 8H17V16H21V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.4s]"
                />
              </svg>
            </div>
          </div>
        )}
        {/* My Collection */}
        {!loading && collection?.id && (
          <>
            <h1 className="mt-10 text-center text-2xl font-bold">
              {collection?.name}
            </h1>
            <section className="xl:flex">
              <figure className="relative mr-2 mt-10 flex grow flex-col">
                <h2
                  className="flex shrink-0 items-center py-5 pl-2 text-white"
                  style={{
                    background: color || '#EAEDF1',
                  }}
                >
                  {localStorage.getItem('persist:root') && isOwner && (
                    <a
                      className="cursor-pointer"
                      href={`/modifier-ma-collecte/${slug}`}
                    >
                      <IconSetting />
                    </a>
                  )}

                  <span className="grow text-center text-lg lg:text-xl">
                    {groupName}
                  </span>
                </h2>
                {image && !collection?.video && (
                  <span
                    className="bg-center h-64 w-full grow bg-cover xl:h-auto"
                    style={{
                      backgroundImage: `url(${image})`,
                    }}
                  />
                )}
                {collection?.video && (
                  <div className="mx-auto h-64 w-full grow">
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      controls
                      url={collection.video}
                    />
                  </div>
                )}
                <figcaption className="absolute bottom-0 right-0 -mb-4 rounded-l-full bg-white py-5 pl-5 text-right">
                  <img
                    src="/assets/thumbs_up.png"
                    alt="Aime"
                    title="Aime"
                    className="mx-auto"
                  />
                  <span className="font-bold">
                    {collection.likes} vote
                    {collection.likes > 1 && 's'}
                  </span>
                </figcaption>
              </figure>
              <div className="mt-10 xl:w-1/2">
                {/* Amount collected */}
                {collection?.publicCollectedAmount && (
                  <figure className="flex items-center border border-gray-300 px-1 py-2">
                    <img
                      src="/assets/gain.png"
                      alt="Soutenir"
                      title="Soutenir"
                      className="ml-2 shrink-0 scale-125"
                    />
                    <figcaption className="grow text-center">
                      <strong className="text-3xl text-black">
                        {reached} €
                      </strong>
                      <span className="block text-sm font-bold text-red-500">
                        collectés
                      </span>
                    </figcaption>
                  </figure>
                )}
                {/* I support with a donation */}
                <div
                  className="items-start py-5 pl-5 pr-5 sm:flex"
                  style={{
                    background: color || '#EAEDF1',
                  }}
                >
                  <figure className="mb-4 flex shrink-0 justify-center sm:mb-0 sm:mr-7 sm:mt-8">
                    <img
                      src="/assets/send_love.png"
                      alt="Love message"
                      title="Je supporte avec un don"
                    />
                  </figure>
                  <aside className="mx-auto text-center text-white">
                    <h2 className="text-xl font-bold">
                      Je supporte avec un don
                    </h2>
                    <div className="flex flex-wrap items-center justify-center gap-4 text-red-500">
                      <Link
                        className="mt-5 w-20 cursor-pointer rounded-full bg-white py-2 text-center font-bold duration-300 hover:scale-105"
                        to={`/offrir-un-don/${slug}`}
                        state={{ amount: '20' }}
                      >
                        20 €
                      </Link>
                      <Link
                        className="mt-5 w-20 cursor-pointer rounded-full bg-white py-2 text-center font-bold duration-300 hover:scale-105"
                        to={`/offrir-un-don/${slug}`}
                        state={{ amount: '50' }}
                      >
                        50 €
                      </Link>
                      <Link
                        className="mt-5 w-20 cursor-pointer rounded-full bg-white py-2 text-center font-bold duration-300 hover:scale-105"
                        to={`/offrir-un-don/${slug}`}
                        state={{ amount: '100' }}
                      >
                        100 €
                      </Link>
                      <Link
                        className="mt-5 w-20 cursor-pointer rounded-full bg-white py-2 text-center font-bold duration-300 hover:scale-105"
                        to={`/offrir-un-don/${slug}`}
                        state={{ amount: '500' }}
                      >
                        500 €
                      </Link>
                      <Link
                        className="mt-5 cursor-pointer rounded-full bg-white px-5 py-2 font-bold text-red-500 duration-300 hover:scale-105"
                        to={`/offrir-un-don/${slug}`}
                      >
                        Choisir un montant
                      </Link>
                    </div>
                  </aside>
                </div>
                {/* Supporters */}
                <figure className="my-3 flex items-center justify-center">
                  <img
                    src="/assets/team.png"
                    alt="Supporters"
                    title="Supporters"
                  />
                  <figcaption className="ml-3 text-xl font-bold text-black">
                    {supporters} Supporters
                  </figcaption>
                </figure>
                {/* Amounts resume */}
                <ul className="mx-auto flex max-w-xs flex-col justify-center sm:mx-0 sm:max-w-8xl sm:flex-row xl:justify-between">
                  {/* Remaining to be collected */}
                  {collection?.publicAmountGoal &&
                    collection?.publicCollectedAmount && (
                      <li className="shrink-0 rounded-md bg-sky-400 px-3 py-4 text-center">
                        <figure>
                          <img
                            src="/assets/make_donation.png"
                            alt="Donner"
                            title="Reste à collecter"
                            className="mx-auto h-10"
                          />
                          <figcaption className="font-bold text-white">
                            <h3 className="my-3 text-xl">
                              {collection.hasFixedAmount &&
                                amountRemainingToBeCollected}
                            </h3>
                            <p>Reste à collecter</p>
                          </figcaption>
                        </figure>
                      </li>
                    )}
                  {/* Amount collected */}
                  <li
                    className="bg-right my-10 flex w-full items-center rounded-xl border border-gray-300 bg-cover bg-no-repeat py-4 text-center font-bold sm:mx-2 sm:my-0"
                    style={{
                      backgroundImage: `url(${listSlides[slide]})`,
                    }}
                  >
                    <figure>
                      <figcaption className="flex w-1/2 px-3">
                        <h3 className="leading-6">
                          La tirelire {groupName} s’élève à <br />
                          {collection?.publicAmountGoal && (
                            <span className="text-red-400">
                              {collection.hasFixedAmount &&
                                collection?.amountGoal}
                              {collection.hasFixedAmount &&
                              collection?.amountGoal > 0
                                ? '€'
                                : ''}
                            </span>
                          )}
                        </h3>

                        {!collection?.publicAmountGoal && (
                          <span className="text-red-400">
                            {collection.hasFixedAmount &&
                              collection?.amountGoal}
                            {collection.hasFixedAmount &&
                            collection?.amountGoal > 0
                              ? '€'
                              : ''}
                          </span>
                        )}
                      </figcaption>
                    </figure>
                  </li>
                  {/* Goal */}
                  {collection?.publicAmountGoal && (
                    <li className="shrink-0 rounded-md bg-primary px-3 py-4 text-white sm:w-36">
                      <figure className="flex flex-col items-center justify-center text-center font-bold">
                        <img
                          src="/assets/target.png"
                          alt="But"
                          title="But"
                          className="h-10"
                        />
                        <figcaption>
                          <h3 className="my-3 text-xl">
                            {collection.hasFixedAmount &&
                              collection?.amountGoal}
                            {collection.hasFixedAmount &&
                            collection?.amountGoal > 0
                              ? '€'
                              : ''}
                          </h3>
                          <p>But</p>
                        </figcaption>
                      </figure>
                    </li>
                  )}
                </ul>
              </div>
            </section>
            {/* Profil */}
            <ul className="flex flex-wrap items-start justify-between xl:w-1/2">
              {/* Profil */}
              <li className="my-5">
                <figure className="flex items-end">
                  <span
                    className="mr-3 h-10 w-10 rounded-full border bg-cover bg-no-repeat"
                    style={{
                      backgroundImage: `url(${collect.profile})`,
                      borderColour: color || '#EAEDF1',
                    }}
                  />
                  <figcaption className="flex items-end">
                    {collect.verified && (
                      <IconCheckCircle color="#058300" dimension="16px" />
                    )}
                    <p className="ml-3">
                      <strong className="block text-black">
                        {collection.publicName}
                      </strong>
                      <span className="text-xs">
                        {collect.verified && 'Profil vérifié'}
                      </span>
                    </p>
                  </figcaption>
                </figure>
              </li>
              {/* Creation date */}
              <li className="my-5 sm:mx-10">
                <figure className="flex items-center">
                  <img
                    src="/assets/clock.png"
                    alt="Horloge"
                    title="Date de création"
                    className="h-10"
                  />
                  <figcaption className="ml-3 font-bold">
                    {renderDate(collection?.date)}
                  </figcaption>
                </figure>
              </li>
              {/* Rate */}
              {collection?.publicCollectedAmount && (
                <li className="my-5 flex h-10 w-10 items-center justify-center rounded-full text-xs">
                  <figure>
                    <figcaption>
                      <div className="collection-stat relative">
                        <svg
                          className="w-14"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="-1 -1 34 34"
                        >
                          <circle
                            cx="16"
                            cy="16"
                            r="15.9155"
                            className="progress-bar__background"
                          />

                          <circle
                            cx="16"
                            cy="16"
                            r="15.9155"
                            className="progress-bar__progress
                  profile-progress-bar"
                            stroke={color}
                          />
                        </svg>
                        <span
                          className={`text-default absolute top-[18px] text-sm font-bold
                  ${progress === 100 ? 'left-2.5' : 'left-3.5'}
                  ${progress < 10 && 'left-4'}`}
                        >
                          {progress}%
                        </span>
                      </div>
                    </figcaption>
                  </figure>
                </li>
              )}
            </ul>
            {/* Details */}
            <section className="flex flex-col justify-between xl:flex-row">
              {/* Dynamic Render */}
              <section className="xl:w-1/2">
                {/* Actions */}
                <ul className="flex flex-wrap justify-between sm:flex-row">
                  <li className="mb-5">
                    <button
                      onClick={() => detailToDisplay(1)}
                      type="button"
                      className={`rounded-full ${
                        step === 1 ? 'text-black' : 'text-white'
                      } w-36 px-10 py-2 text-center duration-300 hover:scale-105`}
                      style={{
                        backgroundColor:
                          step === 1 ? '#cbd5ff4d' : color || '#cbd5ff4d',
                      }}
                    >
                      Description
                    </button>
                  </li>
                  <li className="mb-5">
                    <button
                      onClick={() => detailToDisplay(2)}
                      type="button"
                      className={`rounded-full ${
                        step === 2 ? 'text-black' : 'text-white'
                      } w-36 px-10 py-2 text-center duration-300 hover:scale-105`}
                      style={{
                        backgroundColor:
                          step === 2 ? '#cbd5ff4d' : color || '#cbd5ff4d',
                      }}
                    >
                      Actualité
                    </button>
                  </li>
                  <li className="mb-5">
                    <button
                      onClick={() => detailToDisplay(3)}
                      type="button"
                      className={`rounded-full ${
                        step === 3 ? 'text-black' : 'text-white'
                      } w-36 px-10 py-2 text-center duration-300 hover:scale-105`}
                      style={{
                        backgroundColor:
                          step === 3 ? '#cbd5ff4d' : color || '#cbd5ff4d',
                      }}
                    >
                      Supporters
                    </button>
                  </li>
                </ul>
                {/* Description */}
                {step === 1 && (
                  <article>
                    {/* An article */}
                    {collection?.description ? (
                      <InternalEditor
                        content={description}
                        setContent={(ctn) => setDescrption(ctn)}
                        isPreview
                      />
                    ) : (
                      <p className="text-left">Aucune description</p>
                    )}
                  </article>
                )}
                {/* News */}
                {step === 2 && (
                  <section>
                    {/* Add news */}
                    <label
                      htmlFor="add"
                      className="mb-3 flex items-center justify-between"
                    >
                      <span className="text-2xl font-bold">01/11/2021</span>
                      <input
                        type="button"
                        className="cursor-pointer rounded-full px-4 py-1 text-xs text-white duration-300 hover:scale-105"
                        style={{
                          backgroundColor: color || '#EAEDF1',
                        }}
                        value="Ajouter une actualité"
                        data-hs-overlay="#i-contribute"
                        id="add"
                      />
                    </label>
                    {/* An article */}
                    {articles?.errorLoadingNews[0] ? (
                      <p> {articles?.errorLoadingNews[1]} </p>
                    ) : (
                      <>
                        {articles.news.map((theNew, articleIndex) => {
                          return (
                            <aside key={theNew.id}>
                              <h2 className="text-3xl font-bold">
                                {theNew.titre}
                              </h2>
                              <p className="my-5 text-lg">{theNew.content}</p>
                              <div className="mb-5">
                                <button
                                  type="button"
                                  className="mr-2 cursor-pointer rounded-full px-4 py-1 text-xs text-white duration-300 hover:scale-105"
                                  style={{
                                    backgroundColor: color || '#EAEDF1',
                                  }}
                                  onClick={() => openModifyPopup(articleIndex)}
                                >
                                  Modifier
                                </button>
                                <button
                                  type="button"
                                  className="cursor-pointer rounded-full px-4 py-1 text-xs text-white duration-300 hover:scale-105"
                                  style={{
                                    backgroundColor: color || '#EAEDF1',
                                  }}
                                  onClick={() => openDeletePopup(articleIndex)}
                                >
                                  Supprimer
                                </button>
                              </div>
                            </aside>
                          );
                        })}
                      </>
                    )}
                  </section>
                )}
                {/* Latest Donations Collected */}
                {step === 3 && (
                  <article>
                    <h2 className="text-xl font-bold">
                      Derniers Dons collectés
                    </h2>
                    <ul>
                      {/* Item donation */}
                      {collect.supporters.map((supporter) => {
                        return (
                          <li className="mt-5 border-b">
                            <figure className="flex items-center">
                              <span
                                className="bg-center ml-1 mr-3 h-7 w-7 rounded-full bg-cover"
                                style={{
                                  backgroundImage: `url(${supporter.profile})`,
                                }}
                              />
                              <figcaption>
                                <h3 className="mb-2 text-lg leading-5">
                                  {supporter.name}
                                </h3>
                                <p className="text-xs">
                                  <strong>{supporter.amount} . </strong>
                                  {supporter.date}
                                </p>
                              </figcaption>
                            </figure>
                            <p className="my-5 text-xl"> {supporter.content}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </article>
                )}
              </section>
              {/* Donations */}
              <section className="mx-auto w-full xl:mx-0 xl:max-w-lg">
                {/* Latest Donations Collected */}
                <aside className="p-2">
                  {/* Date */}
                  {collection?.ending_date && (
                    <CountDownTimer
                      date={dayjs(collection?.ending_date)
                        .tz('Europe/Paris', true)
                        .endOf('month')
                        .add(1, 'second')
                        .format()}
                    />
                  )}
                  <h2 className="mt-5 text-xl font-bold">
                    Derniers Dons collectés
                  </h2>
                  {/* List Donations */}
                  <ul>
                    {/* Liste des donations - À remplacer par des données dynamiques
                      <ul>
                        {collect.lastDonation.map((donation, donationIndex) => {
                          return (
                            <li
                              key={donation.id}
                              className={`mt-5 ${
                                donationIndex !== collect.lastDonation.length - 1 &&
                                'border-b'
                              }`}
                            >
                              <figure className="flex items-center">
                                <span
                                  className="bg-center ml-1 mr-3 h-7 w-7 rounded-full bg-cover"
                                  style={{
                                    backgroundImage: `url(${donation.profile})`,
                                  }}
                                />
                                <figcaption>
                                  <h3 className="mb-2 text-lg leading-5">
                                    {donation.name}
                                  </h3>
                                  <p className="text-xs">
                                    <strong>{donation.amount} . </strong>
                                    {donation.date}
                                  </p>
                                </figcaption>
                              </figure>
                            </li>
                          );
                        })}
                      </ul>
                    */}
                  </ul>
                  <div className="mt-5 flex justify-center">
                    <button
                      type="button"
                      className="mx-auto inline-block rounded-full px-2 py-3 text-white duration-300 hover:scale-105"
                      style={{
                        backgroundColor: color || '#EAEDF1',
                      }}
                    >
                      Voir les dons les plus élevés
                    </button>
                  </div>
                </aside>
                {/* Share-Follow */}
                <ul className="mt-2 flex items-end justify-end">
                  <li className="relative mr-6 cursor-pointer">
                    <img
                      src="/assets/share.png"
                      title="Partager"
                      alt="Partager"
                    />
                    <span className="absolute left-10 top-3 font-semibold text-white">
                      Partager
                    </span>
                  </li>
                  <li className="cursor-pointer">
                    <img
                      src="/assets/follow.png"
                      title="Suivre"
                      alt="Aimer et suivre"
                    />
                  </li>
                </ul>
              </section>
            </section>
            {/* Alert */}
            <button
              className="mx-auto flex items-center justify-center"
              type="button"
            >
              <img
                src="/assets/alert.png"
                alt="Alerte"
                title="Signaler cette collecte"
                className="mr-2 h-5"
              />
              <span>Signaler cette collecte</span>
            </button>
          </>
        )}
        {/* Create news */}
        <Formik
          initialValues={{
            title: '',
            explanation: '',
          }}
          validationSchema={NewsInformationSchema}
          onSubmit={(values, { resetForm }) => {
            if (!loading) {
              createArticles({
                collects_id: collection.id,
                titre: values.title,
                content: values.explanation,
              })
                .then(() => {
                  setMessage([true, 'Votre actualité a été ajoutée']);
                  resetForm();
                  getAllArticles(collection.id);
                  setTimeout(() => {
                    window.HSOverlay.close(
                      document.getElementById('i-contribute')
                    );
                    setMessage([]);
                  }, 1500);
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                  setMessage([
                    false,
                    'Une erreur est survenue, veuillez réessayer',
                  ]);
                });
            }
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div
                id="i-contribute"
                className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full min-h-full w-full overflow-y-auto overflow-x-hidden px-5 pb-5 sm:px-10"
              >
                <div className="m-3 mx-auto flex min-h-full items-center justify-center opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:my-10 sm:w-full sm:max-w-3xl">
                  <div className="flex grow flex-col border bg-white pb-7 pt-3 shadow-sm">
                    {/* Close */}
                    <div className="flex items-center justify-end border-b px-4 pb-3">
                      <button
                        type="button"
                        className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white"
                        data-hs-overlay="#i-contribute"
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-3.5 w-3.5"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                    {/* Form */}
                    <section className="mt-3 px-5 sm:px-10 lg:flex lg:pl-20">
                      {/* Logo green */}
                      <figure className="relative mt-14 hidden w-1/3 shrink-0 rounded-l-[40px] bg-green-600/25 py-10 lg:block">
                        <img
                          src="/assets/green_logo.webp"
                          title="KOUDPOUSS"
                          alt="KOUDPOUSS"
                          className="-ml-20"
                        />
                      </figure>
                      {/* Form */}
                      <aside className="auto flex flex-col items-center lg:ml-5 lg:w-2/3">
                        {/* Logo  */}
                        <figure>
                          <img
                            src="/logo.png"
                            title="LASKUADRE"
                            alt="LASKUADRE"
                            className="h-20"
                          />
                        </figure>
                        <Field
                          name="title"
                          placeholder="Ajouter une actualité"
                          className="block w-full rounded-md border border-gray-200 px-4 py-2 text-2xl  focus:border-primary focus:ring-primary"
                        />
                        {errors.title && touched.title ? (
                          <div className="text-red-400">{errors.title}</div>
                        ) : null}
                        <Field
                          name="explanation"
                          as="textarea"
                          rows="15"
                          className="mt-5 block w-full grow rounded-md border border-gray-200 px-4 py-2 text-2xl  focus:border-primary focus:ring-primary"
                        />
                        {errors.explanation && touched.explanation ? (
                          <div className="text-red-400">
                            {errors.explanation}
                          </div>
                        ) : null}
                      </aside>
                    </section>
                    {/* OnSubmit */}
                    <div
                      className="justify-centerpl-20 flex flex-col items-center lg:ml-auto lg:mr-5 lg:w-2/3
                    "
                    >
                      {message.length > 0 && (
                        <div
                          className={`mt-4 text-center text-sm ${
                            message[0] ? 'text-green-500' : 'text-red-500'
                          }`}
                        >
                          {message[1]}
                        </div>
                      )}
                      <button
                        type="submit"
                        className={`mt-4 w-auto rounded-full bg-green-900 px-3 py-1 text-white ${
                          !loading && 'duration-300 hover:scale-105'
                        }`}
                      >
                        {loading ? 'En cours' : 'Valider'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {/* Modify news */}
        <Formik
          innerRef={modifyRef}
          initialValues={{
            title: '',
            explanation: '',
          }}
          validationSchema={NewsInformationSchema}
          onSubmit={(values, { resetForm }) => {
            if (!loading) {
              updateArticles({
                id: articleToModify,
                titre: values.title,
                contenu: values.explanation,
              })
                .then(() => {
                  setMessage([true, 'Votre actualité a été mis à jour']);
                  resetForm();
                  getAllArticles(collection.id);
                  setTimeout(() => {
                    window.HSOverlay.close(
                      document.getElementById('modify-article')
                    );
                    setMessage([]);
                  }, 1500);
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                  setMessage([
                    false,
                    'Une erreur est survenue, veuillez réessayer',
                  ]);
                });
            }
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div
                id="modify-article"
                className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full min-h-full w-full overflow-y-auto overflow-x-hidden px-5 pb-5 sm:px-10"
              >
                <div className="m-3 mx-auto flex min-h-full items-center justify-center opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:my-10 sm:w-full sm:max-w-3xl">
                  <div className="flex grow flex-col border bg-white pb-7 pt-3 shadow-sm">
                    {/* Close */}
                    <div className="flex items-center justify-end border-b px-4 pb-3">
                      <button
                        type="button"
                        className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white"
                        data-hs-overlay="#modify-article"
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-3.5 w-3.5"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                    {/* Form */}
                    <section className="mt-3 px-5 sm:px-10 lg:flex lg:pl-20">
                      {/* Logo green */}
                      <figure className="relative mt-14 hidden w-1/3 shrink-0 rounded-l-[40px] bg-green-600/25 py-10 lg:block">
                        <img
                          src="/assets/green_logo.webp"
                          title="KOUDPOUSS"
                          alt="KOUDPOUSS"
                          className="-ml-20"
                        />
                      </figure>
                      {/* Form */}
                      <aside className="auto flex flex-col items-center lg:ml-5 lg:w-2/3">
                        {/* Logo  */}
                        <figure>
                          <img
                            src="/logo.png"
                            title="LASKUADRE"
                            alt="LASKUADRE"
                            className="h-20"
                          />
                        </figure>
                        <Field
                          name="title"
                          placeholder="Ajouter une actualité"
                          className="block w-full rounded-md border border-gray-200 px-4 py-2 text-2xl  focus:border-primary focus:ring-primary"
                        />
                        {errors.title && touched.title ? (
                          <div className="text-red-400">{errors.title}</div>
                        ) : null}
                        <Field
                          name="explanation"
                          as="textarea"
                          rows="15"
                          className="mt-5 block w-full grow rounded-md border border-gray-200 px-4 py-2 text-2xl  focus:border-primary focus:ring-primary"
                        />
                        {errors.explanation && touched.explanation ? (
                          <div className="text-red-400">
                            {errors.explanation}
                          </div>
                        ) : null}
                      </aside>
                    </section>
                    {/* OnSubmit */}
                    <div
                      className="justify-centerpl-20 flex flex-col items-center lg:ml-auto lg:mr-5 lg:w-2/3
                    "
                    >
                      {message.length > 0 && (
                        <div
                          className={`mt-4 text-center text-sm ${
                            message[0] ? 'text-green-500' : 'text-red-500'
                          }`}
                        >
                          {message[1]}
                        </div>
                      )}
                      <button
                        type="submit"
                        className={`mt-4 w-auto rounded-full bg-green-900 px-3 py-1 text-white ${
                          !loading && 'duration-300 hover:scale-105'
                        }`}
                      >
                        {loading ? 'En cours' : 'Valider'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {/* Delete */}
        <div
          id="delete_this_article"
          className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full min-h-full w-full overflow-y-auto overflow-x-hidden px-5 pb-5 sm:px-10"
        >
          <div className="m-3 mx-auto flex min-h-full items-center justify-center opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:my-10 sm:w-full sm:max-w-3xl">
            <div className="flex grow flex-col border bg-white pb-7 pt-3 shadow-sm">
              {/* Close */}
              <div className="flex items-center justify-between border-b px-4 pb-3">
                <h3 className="font-bold text-gray-800 dark:text-white">
                  Supprimer{' '}
                  <strong>
                    {articleToModify !== null &&
                      articles?.news[articleToModify]?.titre}{' '}
                  </strong>
                </h3>
                <button
                  type="button"
                  className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white"
                  data-hs-overlay="#delete_this_article"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="h-3.5 w-3.5"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              {/* Form */}
              <div className="overflow-y-auto p-4">
                <p className="mt-1 text-gray-800 dark:text-gray-400">
                  Voulez vous vraiment supprimer l&#39;article ? Cette action
                  est irréversible.
                </p>
              </div>
              {/* OnSubmit */}
              <div
                className="justify-centerpl-20 flex flex-col items-center lg:ml-auto lg:mr-5 lg:w-2/3
                    "
              >
                {message.length > 0 && (
                  <div
                    className={`mt-4 text-center text-sm ${
                      message[0] ? 'text-green-500' : 'text-red-500'
                    }`}
                  >
                    {message[1]}
                  </div>
                )}
                <button
                  type="button"
                  className={`mt-4 w-auto rounded-full bg-green-900 px-3 py-1 text-white ${
                    !loading && 'duration-300 hover:scale-105'
                  }`}
                  onClick={deleteThisArticle}
                >
                  {loading ? 'En cours' : 'Confirmer'}
                </button>
              </div>
            </div>
          </div>
        </div>
        {!loading && !collection?.id && (
          <div className="w-full text-center">{message}</div>
        )}
      </section>
    </div>
  );
};

export default Collection;
