/* eslint-disable no-unused-vars */
import { getCategory } from 'api/category';
import {
  closeCollect,
  getCollectAmount,
  getCollectSupporters,
  getCollectVotes,
} from 'api/collection';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import IconHeart from './icons/IconHeart';
import IconSupporters from './icons/IconSupporters';
import IconTarget from './icons/IconTarget';
import IconVote from './icons/IconVote';

const Collection = ({ data, slides, slideIndex }) => {
  const [groupName, setGroupName] = useState('');
  const [likes, setLikes] = useState(0);
  const [supporters, setSupporters] = useState(0);
  const [collectedAmount, setCollectedAmount] = useState(0);
  const [amountRemainingToBeCollected, setAmountRemainingToBeCollected] =
    useState(0);
  const [isClosing, setIsClosing] = useState(false);
  const [message, setMessage] = useState(null);

  const getAmount = async () => {
    getCollectAmount(data?.id).then((res) => {
      setCollectedAmount(res.data.montant);
      setAmountRemainingToBeCollected(
        `${
          data?.amountGoal && data?.amountGoal > 0
            ? Number(data.amountGoal) - Number(res.data.montant || 0)
            : 0
        } ${data?.amountGoal > 0 ? '€' : ''}`
      );
    });
  };

  const fetchCategory = () => {
    getCategory(data?.categoryId).then((res) => {
      setGroupName(res.data.data.title);
    });
  };

  const fetchVotes = () => {
    getCollectVotes(data?.id).then((res) => {
      if (res.data.data?.vote) setLikes(res.data.data.vote);
    });
  };

  const fetchSupporters = () => {
    getCollectSupporters(data?.id).then((res) => {
      if (res.data.data?.nbresupporter)
        setSupporters(res.data.data.nbresupporter);
    });
  };

  const handleCloseCollect = async () => {
    if (data.is_active === 0) {
      setMessage([false, 'Cette collecte est déjà clôturée']);
      return;
    }

    if (window.confirm('Êtes-vous sûr de vouloir clôturer cette collecte ?')) {
      try {
        setIsClosing(true);
        await closeCollect(data.id);
        setMessage([true, 'Votre collecte a été clôturée avec succès']);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (err) {
        setMessage([false, "Une erreur s'est produite. Veuillez réessayer."]);
      } finally {
        setIsClosing(false);
      }
    }
  };

  useEffect(() => {
    getAmount();
    fetchCategory();
    fetchVotes();
    fetchSupporters();
  }, []);
  console.log(data);
  return (
    <section className="mx-auto flex-wrap sm:flex">
      {message && (
        <div
          className={`mb-4 w-full rounded-lg p-4 text-center ${
            message[0]
              ? 'bg-green-100 text-green-700'
              : 'bg-red-100 text-red-700'
          }`}
        >
          {message[1]}
        </div>
      )}
      {/* My Collection  */}
      <div className="text-center font-bold sm:w-36 md:shrink-0 md:pt-8 xl:w-44">
        <Link to={`/collecte/${data.slug}`} className="relative">
          <h2 className="text-xl text-black">{data.name}</h2>
          <figure className="duration-300 hover:scale-105">
            {data.image && !data.video && (
              <span
                className="bg-center mx-auto mt-5 block h-32 w-32 rounded-full bg-cover bg-no-repeat"
                style={{
                  backgroundImage: `url(${data.image})`,
                }}
              />
            )}
            {data.video && (
              <div className="mx-auto mt-5 block h-32 w-32">
                <ReactPlayer
                  width="100%"
                  height="100%"
                  url={data.video}
                  controls
                />
              </div>
            )}
            <figcaption className="mt-10">{groupName}</figcaption>
          </figure>
        </Link>
      </div>
      {/* Statistic */}
      <div className="relative mx-auto shrink-0 px-4 sm:mt-8 sm:max-w-xs md:mt-0 md:max-w-sm">
        {/* Decoration */}
        <ul className="relative hidden h-[250px] w-[250px] items-center justify-center sm:flex xl:h-[350px] xl:w-[350px]">
          <li className="absolute mx-auto h-[250px]  w-[250px] rounded-full border border-gray-400 xl:h-[350px] xl:w-[350px]" />
          <li className="absolute mx-auto h-72 w-72 rounded-full border border-blue-400" />
          <li className="absolute mx-auto h-60 w-60 rounded-full border border-green-400/100" />
          <li className="absolute mx-auto h-44 w-44 rounded-full border border-primary" />
          <li className="absolute mx-auto h-5 w-5 rounded-full border border-gray-400" />
        </ul>
        {/* Stats */}
        <ul className="flex flex-wrap justify-between text-center font-bold text-white sm:block">
          {/* Likes */}
          <li className="mx-auto">
            <figure
              title="Nombre de votes"
              className="mt-5 flex h-32 w-32 flex-col rounded-lg bg-violet-700 px-3 py-6 sm:absolute sm:top-8 sm:mt-0 xl:left-14"
            >
              <IconVote size="32px" color="#fff" />
              <figcaption className="flex grow flex-col justify-between">
                <h3 className="text-base">{likes}</h3>
                <p className="mt-2 text-xs">Nombre de votes</p>
              </figcaption>
            </figure>
          </li>
          {/* Goal */}
          <li className="mx-auto">
            <figure
              title="But"
              className="mt-5 flex h-32 w-32 flex-col rounded-lg bg-primary px-3 py-6 sm:absolute sm:top-48 sm:mt-0 xl:left-14"
            >
              <IconTarget size="8" color="#fff" />
              <figcaption className="flex grow flex-col justify-between">
                <h3 className="text-base">
                  {(data.hasFixedAmount && data.amountGoal) || '-'}{' '}
                  {data.hasFixedAmount && data.amountGoal ? '€' : ''}
                </h3>
                <p className="mt-2 text-xs">But</p>
              </figcaption>
            </figure>
          </li>
          {/* Remaining to be collected */}
          <li className="mx-auto">
            <figure
              title="Reste à collecter"
              className="right-0 mt-5 flex h-32 w-32 flex-col rounded-lg bg-blue-400 px-3 py-6 sm:absolute sm:top-12 xl:right-5"
            >
              <IconHeart size="32px" color="#fff" />
              <figcaption className="flex grow flex-col justify-between">
                <h3 className="text-base">
                  {data.hasFixedAmount ? amountRemainingToBeCollected : '-'}
                </h3>
                <p className="mt-2 text-xs">Reste à collecter</p>
              </figcaption>
            </figure>
          </li>
          {/* Supporters */}
          <li className="mx-auto">
            <figure
              title="Supporters"
              className="right-0 mt-5 flex h-32 w-32 flex-col rounded-lg bg-lime-400 px-3 py-6 sm:absolute sm:top-48 xl:right-5"
            >
              <IconSupporters size="32px" color="#fff" />
              <figcaption className="flex grow flex-col justify-between">
                <h3 className="text-base">{supporters}</h3>
                <p className="mt-2 text-xs">Supporters</p>
              </figcaption>
            </figure>
          </li>
        </ul>
      </div>
      {/* Settings */}
      <div className="mx-auto mt-0 max-w-md grow text-center text-white sm:grow-0">
        <br className="sm:hidden" />
        {/* Actions */}
        <div className="flex flex-col space-y-4 sm:hidden lg:flex lg:flex-row lg:space-x-4 lg:space-y-0">
          <Link
            to={`/modifier-ma-collecte/${data.slug}`}
            className="relative flex w-full items-center justify-center rounded-full bg-primary/50 px-9 py-3 text-xs font-bold leading-tight transition-colors duration-300 hover:scale-105 hover:bg-primary/100 sm:px-3 2xl:px-4"
          >
            Modifier
          </Link>

          <button
            type="button"
            onClick={handleCloseCollect}
            disabled={isClosing}
            className="relative flex w-full items-center justify-center rounded-full bg-primary/50 px-9 py-3 text-xs font-bold leading-tight transition-colors duration-300 hover:scale-105 hover:bg-primary/100 sm:px-3 2xl:px-4"
          >
            {isClosing ? 'Clôture en cours...' : 'Clôturer ma collecte'}
          </button>

          <button
            type="button"
            onClick={() => {}}
            className="relative flex w-full items-center justify-center rounded-full bg-pink-500/50 px-9 py-3 text-xs font-bold leading-tight transition-colors duration-300 hover:scale-105 hover:bg-pink-500/100 sm:px-3 2xl:px-4"
          >
            Retirer ma collecte
          </button>
        </div>
        <h3 className="mb-10 mt-5 rounded-lg bg-secondary py-2 text-2xl font-bold sm:hidden sm:text-4xl lg:block">
          Dons Collectés
          <span className="mt-5 block">{collectedAmount} €</span>
        </h3>
        <figure className="justify-center sm:hidden lg:flex xl:justify-end">
          <img
            src={slides[slideIndex].src}
            alt={slides[slideIndex].title}
            title={slides[slideIndex].title}
            className="mb-8 w-48"
          />
        </figure>

        <div className="mt-24 hidden w-max max-w-2xl sm:flex lg:hidden">
          <div className="w-full">
            <ul className="flex flex-wrap justify-between space-y-8 sm:space-x-2 sm:space-y-0 xl:space-x-3">
              <li>
                <Link
                  to={`/modifier-ma-collecte/${data.slug}`}
                  className="relative flex w-max items-center justify-center rounded-full bg-primary/50 px-9 py-3 text-xs font-bold leading-tight transition-colors duration-300 hover:scale-105 hover:bg-primary/100"
                >
                  Modifier
                </Link>
              </li>

              <li className="mx-auto">
                <button
                  type="button"
                  onClick={handleCloseCollect}
                  disabled={isClosing}
                  className="relative flex w-max items-center justify-center rounded-full bg-primary/50 px-9 py-3 text-xs font-bold leading-tight transition-colors duration-300 hover:scale-105 hover:bg-primary/100"
                >
                  {isClosing ? 'Clôture en cours...' : 'Clôturer ma collecte'}
                </button>
              </li>

              <li className="mx-auto">
                <button
                  type="button"
                  onClick={() => {}}
                  className="relative flex w-max items-center justify-center rounded-full bg-pink-500/50 px-9 py-3 text-xs font-bold leading-tight transition-colors duration-300 hover:scale-105 hover:bg-pink-500/100"
                >
                  Retirer ma collecte
                </button>
              </li>
            </ul>
            <h3 className="mb-10 mt-5 rounded-lg bg-pink-800 py-2 text-2xl font-bold sm:text-4xl">
              Dons Collectés
              <span className="mt-5 block">{collectedAmount} €</span>
            </h3>
          </div>
          <figure className="flex justify-center xl:justify-end">
            <img
              src={slides[slideIndex].src}
              alt={slides[slideIndex].title}
              title={slides[slideIndex].title}
              className="mb-8 w-72"
            />
          </figure>
        </div>
      </div>
    </section>
  );
};

Collection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    categoryId: PropTypes.string.isRequired,
    amountGoal: PropTypes.string.isRequired,
    hasFixedAmount: PropTypes.bool.isRequired,
    slug: PropTypes.string.isRequired,
    video: PropTypes.string,
    publicCollectedAmount: PropTypes.bool.isRequired,
    publicAmountGoal: PropTypes.bool.isRequired,
    is_active: PropTypes.number.isRequired,
  }),
  slideIndex: PropTypes.number.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Collection;
