/* eslint-disable no-restricted-syntax */
import dayjs from 'dayjs';

export function stripeCardNumberValidation(cardNumber) {
  const regexPattern = {
    MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
    VISA: /^4[0-9]{2,}$/,
    AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
    DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
  };
  for (const card in regexPattern) {
    if (cardNumber.replace(/[^\d]/g, '').match(regexPattern[card])) {
      if (cardNumber) {
        return cardNumber &&
          /^[1-6]{1}[0-9]{14,15}$/i.test(
            cardNumber.replace(/[^\d]/g, '').trim()
          )
          ? ''
          : 'Veuillez entrer un numéro de carte valide';
      }
    }
  }
  return 'Veuillez entrer un numéro de carte valide';
}

export const stripeCardExpireValidation = (value) => {
  if (value) {
    if (/^(0[1-9]|1[0-2])\/[0-9]{2}$/i.test(value.trim())) {
      const today = new Date();
      const CurrentDate = dayjs(
        new Date(
          `${today.getFullYear()}-${today.getMonth() + 1}-${new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          ).getDate()}`
        )
      );
      const visaValue = value.split('/');
      const visaDate = new Date(`20${visaValue[1]}`, visaValue[0], 0);
      return CurrentDate < dayjs(visaDate)
        ? undefined
        : 'Veuillez entrer une date valide';
    }
    return 'Veuillez entrer une date valide';
  }
  return undefined;
};

export const textWithSpacesOnly = (value) => {
  if (value) {
    if (/^[a-zA-Z ]*$/i.test(value)) {
      return undefined;
    }
    return 'Seuls les lettres sont autorisés';
  }
  return undefined;
};

export const minLength = (min) => (value) =>
  value && value.length < min
    ? `Doit être au moins 3 caractères ou plus`
    : undefined;
